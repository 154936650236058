import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CgClose } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../../store';
import { postProposal } from '../../store/ProjectsSlice';

type PropTypes = {
  setProposalFormModal: any;
};

const ProposalForm: React.FC<PropTypes> = ({ setProposalFormModal }) => {
  const { id } = useParams();

  const dispatch: AppDispatch = useDispatch();

  const [proposal, setproposal] = useState({
    project_id: id,
    no_of_panels: 0,
    panel_brand: '',
    panel_model: '',
    no_of_inverters: 0,
    inverter_brand: '',
    inverter_model: '',
    monthly_saving_meter: 0,
    excess_production: 0,
    monthly_saving_account: 0,
    monthly_earning_account: 0,
    monthly_earning_plus: 0,
    task_1: 0,
    task_2: 0,
    task_3: 0,
    task_4: 0,
    task_5: 0,
    total_cost: 0,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(postProposal({ data: proposal, id: id }));
  };

  return (
    <div className="z-20 w-screen h-full absolute top-0 left-0 bg-black/75 flex items-center justify-center">
      <button
        onClick={() => setProposalFormModal(false)}
        className="absolute top-10 right-16 text-white text-3xl"
      >
        <CgClose />
      </button>
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="bg-white rounded p-20 flex flex-col justify-center items-center w-1/2"
      >
        <div className="flex">
          <div className="mx-4">
            <div className="flex flex-col">
              <label>No of Panels: </label>
              <input
                placeholder="No of Panels"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    no_of_panels: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Panel Brand: </label>
              <input
                placeholder="Panel Brand"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    panel_brand: e.target.value,
                  })
                }
                className="border p-1 rounded"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label>Panel Model: </label>
              <input
                placeholder="Panel Model"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    panel_model: e.target.value,
                  })
                }
                className="border p-1 rounded"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label>No of Inverters: </label>
              <input
                placeholder="No of Inverters"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    no_of_inverters: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Inverter Brand: </label>
              <input
                placeholder="Inverter Brand"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    inverter_brand: e.target.value,
                  })
                }
                className="border p-1 rounded"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label>Inverter Model: </label>
              <input
                placeholder="Inverter Model"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    inverter_model: e.target.value,
                  })
                }
                className="border p-1 rounded"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label>Net Metering Saving: </label>
              <input
                placeholder="Net Metering Saving"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    monthly_saving_meter: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Excess Production: </label>
              <input
                placeholder="Excess Production"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    excess_production: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
          </div>
          <div className="mx-4">
            <div className="flex flex-col">
              <label>Net Account Saving: </label>
              <input
                placeholder="Net Account Saving"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    monthly_saving_account: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Net Account Earning: </label>
              <input
                placeholder="Net Account Earning"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    monthly_earning_account: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Net Plus Earning: </label>
              <input
                placeholder="Net Plus Earning"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    monthly_earning_plus: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Task 1 Duration: </label>
              <input
                placeholder="Task 1 Duration (Weeks)"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    task_1: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Task 2 Duration: </label>
              <input
                placeholder="Task 2 Duration (Weeks)"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    task_2: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Task 3 Duration: </label>
              <input
                placeholder="Task 3 Duration (Weeks)"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    task_3: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Task 4 Duration: </label>
              <input
                placeholder="Task 4 Duration (Weeks)"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    task_4: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
            <div className="flex flex-col">
              <label>Task 5 Duration: </label>
              <input
                placeholder="Task 5 Duration (Weeks)"
                onChange={(e) =>
                  setproposal({
                    ...proposal,
                    task_5: Number(e.target.value),
                  })
                }
                className="border p-1 rounded"
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-left w-1/2">
          <label>Total Cost: </label>
          <input
            onChange={(e) =>
              setproposal({ ...proposal, total_cost: Number(e.target.value) })
            }
            placeholder="Total Cost (Rs.)"
            className="border rounded px-2 py-1"
            type="number"
          />
        </div>
        <button className="bg-green-600 p-2 rounded text-white w-3/4 mt-6 border-2 border-green-800">
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default ProposalForm;
