import React from 'react';
import { ProductData } from '../../store/types/DashboardTypes';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Legend, Tooltip } from 'chart.js';
import { Link } from 'react-router-dom';

type Props = {
  product_list: ProductData[];
};

const Products: React.FC<Props> = ({ product_list }) => {
  Chart.register(ArcElement, Legend, Tooltip);

  const product_data = {
    labels: product_list
      .slice(0, 4)
      .map((product) => product.name.toUpperCase()),
    datasets: [
      {
        data: product_list.slice(0, 4).map((product) => product.profit),
        lineTension: 0.2,
        borderColor: [
          '#fbbc64',
          '#52D726',
          '#FF7300',
          '#FF0000',
          '#007ED6',
          '#7CDDDD',
        ],
        backgroundColor: [
          '#fbbc64',
          '#52D726',
          '#FF7300',
          '#FF0000',
          '#007ED6',
          '#7CDDDD',
        ],
      },
    ],
  };

  const product_options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: true,
    },
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Chart Title',
      },
    },
  };

  return (
    <div className="flex flex-col bg-white rounded mt-5 shadow-xl p-6 w-5/6">
      <h2 className="font-semibold ">PRODUCTS & SERVICES SUMMARY</h2>
      <div className="flex flex-col lg:flex-row justify-around mt-5 gap-5">
        <div className="w-full">
          <Pie data={product_data} options={product_options} />
        </div>
        <div className="flex flex-col justify-center w-full h-48 overflow-y-scroll product-list">
          <div className="flex justify-between w-full font-semibold px-1 text-xs mb-2">
            <p>NAME</p>
            <p>PROFIT</p>
          </div>
          {product_list.map((product, idx) => (
            <div
              key={idx}
              className="flex justify-between w-full border-b-2 px-1 py-2 text-sm"
            >
              <p>{product.name}</p>
              <p>Rs. {product.profit.toLocaleString()}</p>
            </div>
          ))}
        </div>
      </div>
      <Link
        className="ml-auto mt-5  text-xs font-semibold text-gray-500 hover:text-gray-700 hover:underline ease-in-out"
        to="/products-and-services"
      >
        View Details
      </Link>
    </div>
  );
};

export default Products;
