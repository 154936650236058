import React, { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { imageUpload } from '../../store/ProjectsSlice';

type Props = {
  id: any;
  setimageUploadModal: any;
};

const ImageUpload: React.FC<Props> = ({ setimageUploadModal, id }) => {
  const [images, setimages] = useState<any>({});

  const fd = new FormData();

  const dispatch: AppDispatch = useDispatch();

  const submitImages = async (e: any) => {
    e.preventDefault();

    Object.values(images).forEach((image: any, idx: number) => {
      fd.append(`img_${idx}`, image);
    });

    const data = {
      id: id,
      data: fd,
    };

    await dispatch(imageUpload(data));

    setimageUploadModal(false);
  };

  return (
    <div className='z-20 absolute top-0 left-0 bg-black/75 w-screen h-full flex items-center justify-center'>
      <button
        onClick={() => setimageUploadModal(false)}
        className='text-white text-3xl absolute top-10 right-16'
      >
        <CgClose />
      </button>
      <div className='rounded bg-white p-10'>
        <form
          onSubmit={(e) => submitImages(e)}
          className='flex flex-col justify-center'
        >
          <input
            onChange={(e) => {
              const files = e.target.files as FileList;
              setimages(files);
            }}
            type='file'
            multiple
            className='file:rounded file:border-none file:bg-orange-200 border-2 border-orange-700 rounded'
          />
          <button
            type='submit'
            className='text-white border-2 border-green-800 bg-green-600 font-semibold rounded py-2 px-3 my-2'
          >
            UPLOAD
          </button>
        </form>
      </div>
    </div>
  );
};

export default ImageUpload;
