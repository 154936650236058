import React, { useEffect } from 'react';
import { FaCheckDouble } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getAllLogs } from '../../store/LogSlice';
import LogsList from './LogsList';
import Layout from "../Layout";

const Logs = () => {
  const logs =
    useSelector((state: RootState) => state.log.logs_list.results) || [];

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllLogs());
  }, [dispatch]);

  return (
    <Layout>
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <h1 className='font-semibold flex items-center gap-3 uppercase py-2'>
          <span className={` text-orange-400`}>
            <FaCheckDouble />
          </span>
          Activity Log
        </h1>
      </div>
      <LogsList logs={logs} />
    </Layout>
  );
};

export default Logs;
