import React, { useState } from 'react';
import { Invoice as IInvoice } from '../../store/types/InvoiceTypes';
import Options from './Options';
import { BsThreeDotsVertical } from 'react-icons/bs';
import InvoiceEmail from './InvoiceEmail';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { deleteInvoice, getAllInvoices } from '../../store/InvoiceSlice';

type Props = {
  invoice: IInvoice;
};

type DeleteProps = {
  id: string;
};

const Invoice: React.FC<Props> = ({ invoice }) => {
  const [options, setoptions] = useState(false);
  const [emailModal, setemailModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);

  const filters = {
    search: '',
    page: 0,
    limit: 10,
  };

  const dispatch: AppDispatch = useDispatch();

  const removeInvoice = async (id: string) => {
    await dispatch(deleteInvoice(id));
    setdeleteModal(false);
    dispatch(getAllInvoices(filters));
  };

  const DeleteModal: React.FC<DeleteProps> = ({ id }) => {
    return (
      <div>
        <button
          onClick={() => removeInvoice(id)}
          className='rounded mx-1 bg-red-600 border border-red-800 text-white font-semibold text-xs p-1'
        >
          Delete
        </button>
        <button
          onClick={() => setdeleteModal(false)}
          className='rounded mx-1 bg-green-600 border border-green-800 text-white font-semibold text-xs p-1'
        >
          Cancel
        </button>
      </div>
    );
  };

  return (
    <tr className='border-b relative text-sm' key={invoice.id}>
      <td className='py-4 px-10'>{invoice.invoice_no}</td>
      <td className='py-4 px-10'>{invoice.createdAt?.split('T')[0]}</td>
      <td className='py-4 px-10'>{invoice.project.project_id}</td>
      <td className='py-4 px-10'>{invoice?.user?.user_name}</td>
      <td className='py-4 px-10'>
        {deleteModal ? (
          <DeleteModal id={invoice.id} />
        ) : (
          <button
            className='rounded-full hover:bg-gray-100 duration-200 p-1'
            onClick={() => setoptions(true)}
            onBlur={() =>
              setTimeout(() => {
                setoptions(false);
              }, 200)
            }
          >
            <BsThreeDotsVertical />
          </button>
        )}
      </td>
      {options && (
        <Options
          id={invoice.id || ''}
          setemailModal={setemailModal}
          setdeleteModal={setdeleteModal}
        />
      )}
      {emailModal && (
        <InvoiceEmail
          setemailModal={setemailModal}
          id={invoice.id}
          email={invoice.project.email_address}
        />
      )}
    </tr>
  );
};

export default Invoice;
