import React, { useState, useEffect } from 'react';
import { CgClose } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { postUser, resetPostStatus } from '../../store/UserSlice';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';
import Layout from "../Layout";

type ButtonProps = {
  error: boolean;
};

const NewUser = () => {
  const [user, setuser] = useState({
    first_name: '',
    last_name: '',
    user_name: '',
    email_address: '',
    password: '',
    admin: false,
    password_confirm: '',
  });
  const [check_password, setcheck_password] = useState(false);

  const userPost = useSelector((state: RootState) => state.user.post);
  const postError = userPost.error;
  const postMessage = userPost.message;

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const submitUser = async (e: any) => {
    e.preventDefault();
    if (user.password !== user.password_confirm) {
      return setcheck_password(true);
    }
    setcheck_password(false);
    await dispatch(postUser(user));
  };

  useEffect(() => {
    dispatch(resetPostStatus());
  }, [dispatch]);

  const Button: React.FC<ButtonProps> = ({ error }) => {
    return error ? (
      <button
        onClick={() => {
          dispatch(resetPostStatus());
        }}
        className={`bg-red-100 text-reg-600 rounded-full p-1`}
      >
        <CgClose />
      </button>
    ) : (
      <Link
        className='font-semibold text-green-700 hover:underline flex items-center'
        to='/users'
      >
        <FaArrowCircleLeft />
        <p className='ml-1'>Back to user list</p>
      </Link>
    );
  };

  return (
    <Layout>
      <div className='w-full flex flex-col items-center'>
        <div className='flex  items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
          <div className='flex items-center'>
            <button
              onClick={() => navigate('/users')}
              className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
            >
              <FaArrowCircleLeft />
            </button>
            <h1 className='font-semibold py-2'>NEW USER</h1>
          </div>
        </div>
        <form
          onSubmit={(e) => submitUser(e)}
          className='shadow-lg rounded xl:w-4/5 p-10 xl:my-10 flex flex-col w-full'
        >
          <div className='flex flex-col lg:flex-row w-full justify-between gap-4'>
            <div className='flex flex-col w-full mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                First Name:
              </label>
              <input
                onChange={(e) => setuser({ ...user, first_name: e.target.value })}
                placeholder='First Name'
                required
                className='border py-3 text-sm px-2 rounded'
                type='text'
              />
            </div>
            <div className='flex flex-col w-full mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Last Name:
              </label>
              <input
                onChange={(e) => setuser({ ...user, last_name: e.target.value })}
                placeholder='Last Name'
                required
                className='border py-3 text-sm px-2 rounded'
                type='text'
              />
            </div>
          </div>
          <div className='flex flex-col mb-3 w-full'>
            <label className='my-1 text-sm text-gray-500 font-semibold'>
              E-mail Address:
            </label>
            <input
              onChange={(e) =>
                setuser({ ...user, email_address: e.target.value })
              }
              placeholder='E-mail'
              required
              className='border py-3 text-sm px-2 rounded'
              type='text'
            />
          </div>


          <div className='w-full flex flex-col lg:flex-row gap-4 mb-3'>
            <div className='flex flex-col w-full '>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Username:
              </label>
              <input
                onChange={(e) => setuser({ ...user, user_name: e.target.value })}
                placeholder='Username'
                required
                className='border py-3 text-sm px-2 rounded'
                type='text'
              />
            </div>
            <div className='flex flex-col  w-full  mt-3'>
              <p className='text-sm text-gray-500 font-semibold'>Admin:</p>
              <label className='text-sm text-gray-500 font-semibold flex items-center mt-2  cursor-pointer'>
                <input
                  onChange={(e) => setuser({ ...user, admin: !user.admin })}
                  placeholder='Username'
                  className='w-4  mr-2'
                  type='checkbox'
                />
                Provide User Admin Privileges
              </label>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row w-full gap-4'>
            <div className='flex flex-col w-full'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Password:
              </label>
              <input
                onChange={(e) => setuser({ ...user, password: e.target.value })}
                placeholder='Password'
                required
                className='border py-3 text-sm px-2 rounded'
                type='password'
              />
            </div>
            <div className='flex flex-col w-full'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Re-enter Password:
              </label>
              <input
                onChange={(e) =>
                  setuser({ ...user, password_confirm: e.target.value })
                }
                placeholder='Re-type Password'
                required
                className='border py-3 text-sm px-2 rounded'
                type='password'
              />

              {check_password && (
                <p className='text-red-500 text-xs mt-1'>
                  Passwords do not match.
                </p>
              )}
            </div>
          </div>
          <button
            className={`uppercase mt-4 bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 w-full mt-5 ${check_password && 'outline-red-500'
              }`}
            type='submit'
          >
            Create User
          </button>
          {check_password && (
            <div className='bg-red-200 text-red-600 absolute top-0 left-0 w-full px-3 rounded flex justify-between py-2'>
              <p>Error: Passwords do not match.</p>
              <Button error={true} />
            </div>
          )}
          {postError && (
            <div className='bg-red-200 text-red-600 absolute top-0 left-0 w-full px-3 rounded flex justify-between py-2'>
              <p>Error: {postMessage}</p>
              <Button error={true} />
            </div>
          )}
          {!postError && userPost.message && (
            <div className='bg-green-200 text-green-600 absolute top-0 left-0 w-full px-3 rounded flex justify-between py-2'>
              <p>{postMessage}</p>
              <Button error={false} />
            </div>
          )}
        </form>
      </div>
    </Layout>

  );
};

export default NewUser;
