import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AppDispatch, RootState } from '../../store';
import { getAllTemplates, postBoq } from '../../store/BoqSlice';
import { getAllProjects } from '../../store/ProjectsSlice';
import { FaArrowCircleLeft } from 'react-icons/fa';
import Layout from '../Layout';

const filters = {
  search: '',
};

const NewBoq = () => {
  const [template, settemplate] = useState<any>({});
  const [boq, setboq] = useState({
    boq_template_id: '',
    project_id: '',
  });

  const boq_templates =
    useSelector((state: RootState) => state.boq.boq_template_list) || [];

  const projects =
    useSelector((state: RootState) => state.project.project_list.results) || [];

  const boqTemplateOptions = boq_templates.map((template) => ({
    label: template.boq_name,
    value: template,
  }));

  const projectOptions = projects.map((project) => ({
    label: project.project_id,
    value: project.id,
  }));

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const submitBoq = async (e: any) => {
    e.preventDefault();
    await dispatch(postBoq(boq));
    navigate('/boqs');
  };

  useEffect(() => {
    dispatch(getAllTemplates());
    dispatch(getAllProjects(filters));
  }, [dispatch]);

  return (
    <Layout>
      <div className='w-full flex flex-col items-center'>
        <div className='flex  items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
          <div className='flex items-center'>
            <button
              onClick={() => navigate('/boqs')}
              className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
            >
              <FaArrowCircleLeft />
            </button>
            <h1 className='font-semibold py-2'>NEW BOQ</h1>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            submitBoq(e);
          }}
          className='shadow-lg rounded w-5/6 my-20 p-10 flex flex-col '
        >
          <h2 className='font-semibold capitalize mb-1 text-lg'>New BOQ</h2>
          <p className='font-semibold capitalize mb-4 text-sm text-gray-400'>
            Create new Bill of Quantity
          </p>
          <div className='flex w-full justify-center items-end mb-4'>
            <div className='flex flex-col w-full'>
              <label className='font-semibold'>Select Project :</label>
              <Select
                onChange={(e) => setboq({ ...boq, project_id: e?.value || '' })}
                className='w-72'
                options={projectOptions}
              />
            </div>

            <div className='flex flex-col w-full'>
              <label className='font-semibold'>Select BOQ :</label>
              <Select
                onChange={(e) => {
                  settemplate(e?.value.items);
                  setboq({ ...boq, boq_template_id: e?.value.id });
                }}
                className='w-72'
                options={boqTemplateOptions}
              />
            </div>
          </div>

          <div className='flex text-xs justify-around gap-2'>
            <div>
              {Object.entries(template)
                .slice(0, 29)
                .map(([key, value]) => (
                  <div key={key} className='flex w-full justify-between'>
                    <p>{key}</p>
                    <p className='font-semibold'>- {String(value)}</p>
                  </div>
                ))}
            </div>
            <div>
              {Object.entries(template)
                .slice(30, 59)
                .map(([key, value]) => (
                  <div key={key} className='flex w-full justify-between'>
                    <p>{key}</p>
                    <p className='font-semibold'>- {String(value)}</p>
                  </div>
                ))}
            </div>
            <div>
              {Object.entries(template)
                .slice(60, 89)
                .map(([key, value]) => (
                  <div key={key} className='flex w-full justify-between'>
                    <p>{key}</p>
                    <p className='font-semibold'>- {String(value)}</p>
                  </div>
                ))}
            </div>
            <div>
              {Object.entries(template)
                .slice(90)
                .map(([key, value]) => (
                  <div key={key} className='flex w-full justify-between'>
                    <p>{key}</p>
                    <p className='font-semibold'>- {String(value)}</p>
                  </div>
                ))}
            </div>
          </div>

          <button
            type='submit'
            disabled={boq.project_id === '' || boq.boq_template_id === ''}
            className='bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 disabled:opacity-50 disabled:bg-green-600 mt-5 uppercase'
          >
            Send For Approval
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default NewBoq;
