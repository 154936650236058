import React from 'react';
import { FaPaperPlane, FaTrash, FaDownload } from 'react-icons/fa';

type Props = {
  id: string;
  setemailModal: any;
  setdeleteModal: any;
};

const Options: React.FC<Props> = ({ id, setemailModal, setdeleteModal }) => {
  return (
    <td className='absolute top-6 right-24 bg-white z-10 text-xs'>
      <li className='list-none p-1 border'>
        <button
          onClick={() => setemailModal(true)}
          className='flex items-center'
        >
          <FaPaperPlane className='mr-1' /> Send as e-mail
        </button>
      </li>
      <li className='list-none p-1 border-b border-x'>
        <a
          target='_blank'
          href={`https://sunterra-admin-server.herokuapp.com/api/invoices/download/${id}`}
          className='flex items-center cursor-pointer'
          rel='noreferrer'
        >
          <FaDownload className='mr-1' /> Download
        </a>
      </li>
      <li className='list-none p-1 border-b border-x'>
        <button
          onClick={() => setdeleteModal(true)}
          className='flex items-center'
        >
          <FaTrash className='mr-1' /> Delete
        </button>
      </li>
    </td>
  );
};

export default Options;
