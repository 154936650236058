import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { CgClose } from 'react-icons/cg';
import { emailProposal } from '../../store/ProjectsSlice';
import { useSelector } from 'react-redux';
import type { RootState } from '../../store';
import { MdOutlineDone } from 'react-icons/md';

type PropTypes = {
  project_id: string | undefined;
  email: string | undefined;
  setemailModal: any;
};

const ProposalEmail: React.FC<PropTypes> = ({
  project_id,
  email,
  setemailModal,
}) => {
  const [email_address, setemail_address] = useState(email);

  const emailState = useSelector((state: RootState) => state.project.email);

  const dispatch: AppDispatch = useDispatch();

  const data = {
    id: project_id,
    data: {
      email: email_address,
    },
  };

  return (
    <div className='z-20 bg-black/75 absolute w-screen h-full top-0 left-0 flex justify-center items-center'>
      <button
        onClick={() => setemailModal(false)}
        className='text-3xl text-white absolute top-10 right-14'
      >
        <CgClose />
      </button>
      {emailState.success === false && emailState.error === false ? (
        <div className='p-6 bg-white rounded flex'>
          <input
            className='py-2 px-4 border rounded-l'
            type='text'
            onChange={(e) => setemail_address(e.target.value)}
            defaultValue={email_address}
          />
          <button
            onClick={() => dispatch(emailProposal(data))}
            className='py-2 px-3 bg-green-500 text-white font-semibold rounded-r'
          >
            SEND
          </button>
        </div>
      ) : emailState.success === true ? (
        <div className='p-6 bg-white rounded flex'>
          <p className='flex items-center'>
            <MdOutlineDone className='border-2 border-green-600 text-green-600 rounded-full p-1 mr-3 text-3xl' />
            E-mail sent successfully
          </p>
        </div>
      ) : (
        <div className='p-6 bg-white rounded flex'>
          <p className='flex items-center'>
            <CgClose className='border-2 border-red-600 text-red-600 rounded-full p-1 mr-3 text-3xl' />
            Error sending email
          </p>
        </div>
      )}
    </div>
  );
};

export default ProposalEmail;
