import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../../store';
import { postLogin } from '../../store/UserSlice';
import { useNavigate } from 'react-router-dom';
import bg from '../../images/bg.png';
import loginImage from '../../images/login.png';

import logo from '../../images/logo.png';

const Login = () => {
  const [user_name, setuser_name] = useState('');
  const [password, setpassword] = useState('');

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (e: any) => {
    e.preventDefault();
    const credentials = { user_name, password };
    await dispatch(postLogin(credentials));
    const authorization = await localStorage.getItem('authorization');
    if (authorization) {
      navigate('/dashboard');
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className='min-h-screen flex justify-center items-center relative bg-black'>
      <div
        className='absolute w-full h-full left-0 top-0 z-0'
        style={{
          backgroundImage: `url(${bg})`,
          opacity: 0.35,
          backgroundSize: 'cover',
          // Blur
          filter: 'blur(3px)',
        }}
      />
      <div
        className='z-10 grid md:grid-cols-2 w-5/6 xl:w-4/6  shadow-xl'
        style={{ maxWidth: '1200px' }}
      >
        <div className=' rounded-tl-lg rounded-bl-lg bg-white py-20 px-6 md:px-10 xl:px-14 flex flex-col items-center justify-center '>
          {/* Logo */}
          <img src={logo} alt='logo' className='mx-auto mb-2' />
          <h1 className='font-semibold mb-5 text-center'>
            Sunterra Admin System
          </h1>

          <div className='my-6 w-full'>
            <h2 className='font-semibold text-2xl'>Welcome</h2>
            <h2 className='font-semibold text-gray-400 text-sm '>
              Please login to continue
            </h2>
          </div>
          <form
            className='flex flex-col w-full'
            onSubmit={(e) => {
              login(e);
            }}
          >
            <label className='text-gray-400 font-semibold text-xs flex flex-col'>
              Username
              <input
                onChange={(e) => setuser_name(e.target.value)}
                className='border py-3 text-sm px-2 rounded mt-2'
                type='text'
                placeholder='Username'
                name='username'
              />
            </label>

            <label className='text-gray-400 font-semibold text-xs flex flex-col mt-3 mb-6'>
              Password
              <input
                onChange={(e) => setpassword(e.target.value)}
                className='border py-3 text-sm px-2 rounded mt-2'
                type='password'
                placeholder='Password'
                name='password'
              />
            </label>

            <button className='bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 w-full'>
              LOGIN
            </button>
          </form>
          <p className='text-gray-500 text-xs mt-6 '>
            Forgot password?{' '}
            <span className='underline cursor-pointer'>Reset Password</span>
          </p>
        </div>

        <div className='hidden md:block '>
          <img
            src={loginImage}
            alt=''
            className='rounded-tr-lg rounded-br-lg w-full h-full'
            style={{ objectFit: 'cover' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
