import React from 'react';
import { User as TUser } from '../../store/types/UserTypes';
import { Link } from 'react-router-dom';

type Props = {
  user: TUser;
};

const User: React.FC<Props> = ({ user }) => {
  return (
    <tr key={user.id} className='border-b text-sm'>
      <td className='py-4 px-10'>{user.first_name}</td>
      <td className='py-4 px-10'>{user.last_name}</td>
      <td className='py-4 px-10'>{user.user_name}</td>
      <td className='py-4 px-10'>{user.admin ? 'Admin' : 'User'}</td>
      <td className='py-4 px-10'>{user.email_address}</td>
      <td className='py-4 px-10'>{user.createdAt?.split('T')[0]}</td>
      <td className='py-4 px-10'>
        <Link to={`/users/${user.id}`}>Details</Link>
      </td>
    </tr>
  );
};

export default User;
