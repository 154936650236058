import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';
import { deleteProject } from '../../store/ProjectsSlice';

type Props = {
  setdeleteModal: any;
  id: any;
};

const DeleteModal: React.FC<Props> = ({ setdeleteModal, id }) => {
  const [deleteConfirmation, setdeleteConfirmation] = useState('');

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const deleteProjectConfirmation = async () => {
    if (deleteConfirmation.toLowerCase() === 'delete') {
      await dispatch(deleteProject(id));
      navigate('/projects');
    }
  };

  return (
    <div className='bg-black/75 z-20 absolute top-0 left-0 h-full w-screen flex justify-center items-center'>
      <div className='bg-white rounded flex flex-col items-center p-10 w-1/2 text-center'>
        <p className='m-1'>
          Please type 'DELETE' to confirm and proceed to deleting the project.
        </p>
        <input
          onChange={(e) => setdeleteConfirmation(e.target.value)}
          className='border rounded p-2 m-1'
          type='text'
          placeholder='DELETE'
        />
        <div className='flex justify-center w-full'>
          <button
            onClick={() => deleteProjectConfirmation()}
            className='bg-red-600 border border-red-800 rounded px-3 py-2 m-2 text-white font-semibold'
          >
            CONFIRM
          </button>
          <button
            onClick={() => setdeleteModal(false)}
            className='bg-green-600 border border-green-800 rounded px-3 py-2 m-2 text-white font-semibold'
          >
            CANCEL
          </button>
        </div>
        <p className='rounded py-1 px-2 bg-orange-100 m-1'>
          <strong>CAUTION</strong>: Deleting a project will result in deleting
          all other documents associated with it (receipts, invoices, etc). This
          action cannot be reversed, the project will be deleted permanently!
        </p>
      </div>
    </div>
  );
};

export default DeleteModal;
