import React, { useState, useEffect } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import { getAllBoqs } from '../../store/BoqSlice';
import BoqList from './BoqList';
import Layout from "../Layout";

const Boqs = () => {
  const [filters, setfilters] = useState({
    page: 0,
    limit: 10,
  });

  const boqs = useSelector((state: RootState) => state.boq.boq_list) || [];

  const dispatch: AppDispatch = useDispatch();

  const total_pages: number[] = Array.from(
    Array(Math.round(boqs.total_pages)).keys()
  );

  let pages: number[] = [];

  if (filters.page > 0) {
    pages = total_pages.slice(filters.page - 1, filters.page + 2);
  } else {
    pages = total_pages.slice(0, 3);
  }

  useEffect(() => {
    dispatch(getAllBoqs(filters));
  }, [filters, dispatch]);

  return (
    <Layout>
      <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <h1 className='font-semibold flex items-center gap-3 uppercase py-2'>
          <span className={` text-orange-400`}>
            <FaFileAlt />
          </span>
          BOQ's
        </h1>
        <Link
          className='bg-green-600 py-2 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 ml-5'
          to='/boqs/new-boq'
        >
          New BOQ
        </Link>
      </div>
      <BoqList boqs={boqs.results} />
      <div className='flex items-center justify-end px-10 py-3'>
        <div className='flex items-center text-sm'>
          <p>Items per page: </p>
          <select
            className='border rounded px-2 py-1 ml-1 mr-2'
            onChange={(e) => {
              setfilters({ ...filters, limit: Number(e.target.value) });
            }}
          >
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='20'>20</option>
          </select>
        </div>
        <div>
          <button
            onClick={() => {
              filters.page > 0 &&
                setfilters({ ...filters, page: filters.page - 1 });
            }}
            className='py-1 px-3 border rounded m-1 hover:bg-orange-100 duration-200'
          >
            {'<'}
          </button>
          {pages.map((page) => (
            <button
              onClick={() => {
                setfilters({ ...filters, page: page });
              }}
              className={`py-1 px-3 border rounded m-1 hover:bg-orange-100 duration-200 ${page === filters.page && 'bg-orange-100'
                }`}
              key={page}
            >
              {page + 1}
            </button>
          ))}
          <button
            onClick={() => {
              filters.page < total_pages.length - 1 &&
                setfilters({ ...filters, page: filters.page + 1 });
            }}
            className='py-1 px-3 border rounded m-1 hover:bg-orange-100 duration-200'
          >
            {'>'}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Boqs;
