import React, { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { getAllProducts, putProduct } from '../../store/ProductSlice';
import { Product } from '../../store/types/ProductTypes';

type Props = {
  product: Product;
  setupdateModal: any;
};

const UpdateProduct: React.FC<Props> = ({ setupdateModal, product }) => {
  const filters = {
    search: '',
    page: 0,
    limit: 10,
  };

  const [updatedProduct, setupdatedProduct] = useState({
    product_name: product.product_name,
    description: product.description,
    type: product.type,
  });

  const dispatch: AppDispatch = useDispatch();

  const updateProduct = async (e: any) => {
    e.preventDefault();

    const data = {
      id: product.id,
      body: updatedProduct,
    };

    await dispatch(putProduct(data));
    await dispatch(getAllProducts(filters));
    setupdateModal(false);
  };

  return (
    <div className='bg-black/50 h-full w-screen fixed top-0 left-0 flex justify-center items-center z-20'>
      <button
        onClick={() => setupdateModal(false)}
        className='text-4xl absolute top-10 right-14 text-white'
      >
        <CgClose />
      </button>
      <div className='bg-white rounded p-8'>
        <h1 className='text-center mb-2 font-semibold'>
          EDIT PRODUCT / SERVICE
        </h1>
        <form onSubmit={(e) => updateProduct(e)}>
          <div className='flex flex-col w-full'>
            <label>Title: </label>
            <input
              required
              onChange={(e) =>
                setupdatedProduct({
                  ...updatedProduct,
                  product_name: e.target.value,
                })
              }
              defaultValue={product.product_name}
              className='border rounded py-1 px-2'
              type='text'
              placeholder='Product/Service title'
            />
          </div>
          <div className='flex flex-col w-full'>
            <label>Description (optional): </label>
            <input
              onChange={(e) =>
                setupdatedProduct({
                  ...updatedProduct,
                  description: e.target.value,
                })
              }
              defaultValue={product.description}
              className='border rounded py-1 px-2'
              type='text'
              placeholder='Description (optional)'
            />
          </div>
          <div className='flex flex-col w-full'>
            <label>Type: </label>
            <select
              defaultValue={product.type}
              onChange={(e) =>
                setupdatedProduct({ ...updatedProduct, type: e.target.value })
              }
              className='py-1 px-2 rounded border'
            >
              <option value='Product'>Product</option>
              <option value='Service'>Service</option>
            </select>
          </div>
          <button className='rounded w-full bg-green-600 border border-green-800 text-white font-semibold mt-5 p-2'>
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateProduct;
