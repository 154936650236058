import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { InitialState } from './types/LogTypes';

const api = process.env.REACT_APP_API;

export const getAllLogs = createAsyncThunk(
  'logs/get',
  async (): Promise<any> => {
    const response = await axios.get(`${api}/logs`);

    return response.data.logs;
  }
);

const initialState: InitialState = {
  logs_list: {
    results: [],
  },
};

const LogSlice = createSlice({
  name: 'log',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLogs.fulfilled, (state, action) => {
      state.logs_list.results = action.payload;
    });
  },
});

export default LogSlice.reducer;
