import React from 'react';
import { CgClose } from 'react-icons/cg';

type Props = {
  setemailModal: any;
  id: any;
  email: any;
};

const InvoiceEmail: React.FC<Props> = ({ setemailModal, id, email }) => {
  return (
    <div className='fixed bg-black/75 z-10 top-0 left-0 w-screen h-screen flex items-center justify-center'>
      <button
        onClick={() => setemailModal(false)}
        className='text-3xl text-white absolute top-10 right-16'
      >
        <CgClose />
      </button>
      <form className='bg-white rounded p-8 flex items-center'>
        <input
          defaultValue={email}
          className='border rounded py-2 px-3'
          type='text'
        />
        <button
          className='bg-green-500 border-2 border-green-800 text-white font-semibold py-2 px-3 rounded mx-2'
          type='submit'
        >
          SEND
        </button>
      </form>
    </div>
  );
};

export default InvoiceEmail;
