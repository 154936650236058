import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getOneProject,
  getProposal,
  updateProject,
} from '../../store/ProjectsSlice';
import { CgEnter, CgClose } from 'react-icons/cg';
import {
  FaHammer,
  FaImage,
  FaPaperPlane,
  FaSave,
  FaSpinner,
  FaTrash,
} from 'react-icons/fa';

import type { RootState } from '../../store';
import type { AppDispatch } from '../../store';

import ProposalForm from './ProposalForm';
import ProposalEmail from './ProposalEmail';
import StatusUpdate from './StatusUpdate';
import DeleteModal from './DeleteModal';
import ImageUpload from './ImageUpload';
import { getAllUsers } from '../../store/UserSlice';
import InstallationImages from './InstallationImages';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';
import Layout from '../Layout';
import LogsModal from './LogsModal';

const ProjectDetails = () => {
  const navigate = useNavigate();

  const [image_modal, setimage_modal] = useState(false);
  const [current_image, setcurrent_image] = useState('');
  const [proposalFormModal, setProposalFormModal] = useState(false);
  const [statusUpdateModal, setStatusUpdateModal] = useState(false);
  const [emailModal, setemailModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [imageUploadModal, setimageUploadModal] = useState(false);
  const [installationImageModal, setinstallationImageModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [installation_date, setinstallation_date] = useState('');

  const project = useSelector((state: RootState) => state.project.project);

  const proposalStatus = project.proposal;

  const statuses = [
    'Consultation',
    'Advanced Payment',
    'Clearance Documents',
    'Installation Date',
    'BOQ',
    'Local Purchase',
    'Installation',
    'Installation Document',
    'Final Visit',
    'Final Costing',
    'Invoice',
    'Handover Documents',
    'Completed',
  ];

  const nextStatus = statuses[statuses.indexOf(project.status || '') + 1];

  const installationStatus = project.installation_date ? true : false;

  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      dispatch(getAllUsers());
      await dispatch(getOneProject(id));
      await dispatch(getProposal(id));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, id]);

  const openImage = (img: any) => {
    setimage_modal(true);
    setcurrent_image(img);
  };

  const setInstallationDate = async (e: any) => {
    e.preventDefault();

    const data = {
      id: id,
      body: {
        installation_date: installation_date,
      },
    };

    await dispatch(updateProject(data));
    dispatch(getProposal(id));
  };

  return (
    <Layout>
      <div className='w-full flex flex-col items-center'>
        <div className='flex  items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
          <div className='flex items-center'>
            <button
              onClick={() => navigate('/projects')}
              className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
            >
              <FaArrowCircleLeft />
            </button>
            <h1 className='font-semibold py-2 uppercase'>Project Details</h1>
          </div>
        </div>
        <div className='font-semibold flex flex-col shadow-lg rounded p-10 my-10 w-5/6'>
          <div className='mb-5'>
            <ul className='flex gap-5 items-center text-xs border-b border-gray-300 pb-5'>
              {installationStatus ? (
                <button
                  onClick={() => setimageUploadModal(true)}
                  className='flex items-center gap-2 justify-center rounded-full text-gray-300 hover:text-gray-400 text-lg mr-3  duration-100'
                >
                  <FaHammer />
                  <p className='text-xs text-gray-800'>
                    Installation Date:{' '}
                    <span className='font-bold'>
                      {project.installation_date}
                    </span>
                  </p>
                </button>
              ) : (
                <form
                  onSubmit={(e) => setInstallationDate(e)}
                  className='flex rounded-lg'
                >
                  <input
                    className='px-3 rounded-l  border-gray-400 border border-r-0'
                    onChange={(e) => setinstallation_date(e.target.value)}
                    type='date'
                    placeholder='Installation Date'
                  />
                  <button
                    className='bg-green-500 hover:bg-green-600 disabled:bg-orange-200 text-white p-2'
                    disabled={!installation_date}
                  >
                    Save
                  </button>
                </form>
              )}
              <li className='flex items-center'>
                {project.installation_images?.length === 0 ? (
                  <button
                    onClick={() => setimageUploadModal(true)}
                    className='flex items-center gap-2 justify-center rounded-full text-gray-300 hover:text-gray-400 text-lg mr-3  duration-100'
                  >
                    <FaImage />
                    <span className='text-xs text-gray-800'>
                      Add Installation Images
                    </span>
                  </button>
                ) : (
                  <button
                    onClick={() => setinstallationImageModal(true)}
                    className='flex items-center gap-2 justify-center rounded-full text-gray-300 hover:text-gray-400 text-lg mr-3  duration-100'
                  >
                    <FaImage />
                    <span className='text-xs text-gray-800'>View Images</span>
                  </button>
                )}
              </li>
              {loading ? (
                <li className='animate-spin text-gray-300'>
                  <FaSpinner />
                </li>
              ) : proposalStatus ? (
                <li className='fade-in flex gap-5'>
                  <a
                    target='_blank'
                    href={`https://sunterra-admin-server.herokuapp.com/api/proposals/download/${id}`}
                    rel='noreferrer'
                    className='flex items-center gap-2 justify-center rounded-full text-gray-300 hover:text-gray-400 text-lg  duration-100'
                  >
                    <FaSave />
                    <span className='text-xs text-gray-800'>
                      Download Proposal
                    </span>
                  </a>

                  <button
                    onClick={() => setemailModal(true)}
                    className='flex items-center gap-2 justify-center rounded-full text-gray-300 hover:text-gray-400 text-lg  duration-100'
                  >
                    <FaPaperPlane />
                    <span className='text-xs text-gray-800'>
                      Email Proposal
                    </span>
                  </button>
                </li>
              ) : (
                <li className='flex items-center fade-in'>
                  <button
                    onClick={() => setProposalFormModal(true)}
                    className='flex items-center gap-2 justify-center rounded-full text-gray-300 hover:text-gray-400 text-lg mr-3  duration-100'
                  >
                    <FaSave />
                    <span className='text-xs text-gray-800'>
                      Create Proposal
                    </span>
                  </button>
                </li>
              )}

              <li className='ml-auto'>
                <button
                  onClick={() => setdeleteModal(true)}
                  className='flex items-center gap-2 justify-center rounded-full text-sm duration-100 text-red-500 hover:text-red-600'
                >
                  <FaTrash />
                  <span className='text-xs text-gray-800'>Delete Project</span>
                </button>
              </li>
            </ul>
          </div>

          <div className='font-semibold flex justify-center'>
            <div className='px-10 w-1/2'>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Project ID:{' '}
                </span>
                {project.project_id}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Customer Name:{' '}
                </span>
                {project.customer?.first_name +
                  ' ' +
                  project.customer?.last_name}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Site Address:{' '}
                </span>
                {project.site_address.join(' ')}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  GPS Coordinates:{' '}
                </span>{' '}
                Lattitude - {project.gps_coordinates.lat}, Longitude -{' '}
                {project.gps_coordinates.lon}
              </p>

              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Contact No:{' '}
                </span>
                {project.contact_number}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  E-mail address:{' '}
                </span>
                {project.email_address}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Average Usage (Units):{' '}
                </span>
                {project.avg_monthly_usage}
              </p>

              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  WiFi:{' '}
                </span>
                {project.wifi ? 'Available' : 'Not Available'}
              </p>

              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Phase Allocation:{' '}
                </span>
                {project.phase}
              </p>
            </div>
            <div className='px-10 w-1/2'>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Amperage:{' '}
                </span>
                {project.amperage}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Utility:{' '}
                </span>
                {project.utility}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Panel Capacity:{' '}
                </span>
                {project.panel_capacity}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Inverter Capacity:{' '}
                </span>
                {project.inverter_capacity}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Roof Type:{' '}
                </span>
                {project.roof_type}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-b border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Roof Access:{' '}
                </span>
                {project.roof_access}
              </p>
              <p className='flex flex-col font-semibold text-gray-800 border-gray-200 w-full py-3 text-sm px-2 rounded'>
                <span className='my-1 text-sm text-gray-500 font-semibold'>
                  Ladder Type:{' '}
                </span>
                {project.ladder_type}
              </p>

              <div className='flex font-semibold text-gray-800  w-full text-sm rounded bg-gray-50 mt-6 shadow'>
                <div className=' flex flex-col gap-3 justify-center  py-4 px-5 '>
                  <p className='flex flex-col font-semibold  w-full  text-sm rounded'>
                    <span className='text-sm font-bold'>Current User: </span>
                    {project.user?.user_name} - {project.user?.email_address}
                  </p>

                  <div className='flex flex-col '>
                    <span className='text-sm font-bold'>Status: </span>
                    <div>{project.status}</div>
                  </div>
                </div>

                {project.status !== 'Completed' && (
                  <button
                    onClick={() => setStatusUpdateModal(true)}
                    className='bg-green-400 py-3 px-5 rounded-tr rounded-br font-bold hover:shadow-xl hover:bg-green-500 text-white text-2xl duration-100  ml-auto h-100'
                  >
                    <CgEnter />
                  </button>
                )}
              </div>
            </div>

            <div className='min-w-fit px-10'>
              <h4 className='mb-3'>Project Images</h4>
              <div className='flex flex-col gap-4 items-center py-2'>
                <img
                  onClick={() => openImage(project.bill_img_url)}
                  className='w-36 h-1/4 cursor-pointer hover:scale-110 duration-200 rounded object-cover object-top'
                  style={{ objectFit: 'contain' }}
                  src={project.bill_img_url}
                  alt='bill'
                />
                <img
                  onClick={() => openImage(project.id_img_url)}
                  className='w-36 h-1/4  cursor-pointer hover:scale-110 duration-200 rounded object-cover object-top'
                  style={{ objectFit: 'contain' }}
                  src={project.id_img_url}
                  alt='id'
                />
                <img
                  onClick={() => openImage(project.entrance_img_url)}
                  className='w-36 h-1/4  cursor-pointer hover:scale-110 duration-200 rounded object-cover object-top'
                  style={{ objectFit: 'contain' }}
                  src={project.entrance_img_url}
                  alt='entrance'
                />
                <img
                  onClick={() => openImage(project.bank_account_img_url)}
                  className='w-36 h-1/4  cursor-pointer hover:scale-110 duration-200 rounded object-cover object-top'
                  style={{ objectFit: 'contain' }}
                  src={project.bank_account_img_url}
                  alt='bank account'
                />
              </div>
            </div>

            {proposalFormModal && (
              <ProposalForm setProposalFormModal={setProposalFormModal} />
            )}
            {emailModal && (
              <ProposalEmail
                project_id={project.id}
                email={project.customer?.email_address}
                setemailModal={setemailModal}
              />
            )}
            {installationImageModal && (
              <InstallationImages
                setinstallationImageModal={setinstallationImageModal}
                images={project.installation_images || []}
              />
            )}
            {image_modal && (
              <div className='z-20 fixed bg-black/75 w-screen min-h-screen flex justify-center items-center top-0 left-0'>
                <button
                  className='absolute top-20 right-28 text-white text-4xl'
                  onClick={() => setimage_modal(false)}
                >
                  <CgClose />
                </button>
                <img className='w-1/2' src={current_image} alt='not found' />
              </div>
            )}
            {statusUpdateModal && (
              <StatusUpdate
                project_id={project.id || ''}
                status={project.status || ''}
                nextStatus={nextStatus}
                setupdatemodal={setStatusUpdateModal}
              />
            )}
            {deleteModal && (
              <DeleteModal id={id} setdeleteModal={setdeleteModal} />
            )}
            {imageUploadModal && (
              <ImageUpload
                setimageUploadModal={setimageUploadModal}
                id={project.id}
              />
            )}
          </div>
          <LogsModal
            projectId={project.project_id}
            logs={project.logs}
            status={project.status || ''}
            statuses={statuses}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ProjectDetails;
