import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import type { InitialState } from './types/InvoiceTypes'

const api = process.env.REACT_APP_API

const authHeaderConfig = {
  headers: {
    authorization: localStorage.getItem('authorization')
  }
}

export const getAllInvoices = createAsyncThunk(
  'invoices/get',
  async (filters: any): Promise<any> => {
    const response = await axios.get(
      `${api}/invoices?search=${filters.search}&page=${filters.page}&limit=${filters.limit}`,
      authHeaderConfig
    )

    return response.data.invoices
  }
)

export const postInvoice = createAsyncThunk(
  'invoice/post',
  async (data: any): Promise<any> => {
    const response = await axios.post(`${api}/invoices`, data, authHeaderConfig)

    return response.data.invoice
  }
)

export const sendInvoice = createAsyncThunk(
  'invoice/email',
  async (data: any): Promise<any> => {
    const response = await axios.post(
      `${api}/invoices/send-email/${data.id}`,
      data.data
    )

    return response.data
  }
)

export const deleteInvoice = createAsyncThunk(
  'invoice/delete',
  async (id: string): Promise<any> => {
    const response = await axios.delete(`${api}/invoices/${id}`)

    return response.data
  }
)

const initialState: InitialState = {
  pending: false,
  error: false,
  invoice_list: {
    results: [],
    total_pages: 0
  }
}

const InvoiceSlice = createSlice({
  name: 'invoice',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllInvoices.fulfilled, (state, action) => {
      state.invoice_list.results = action.payload
    })
  }
})

export default InvoiceSlice.reducer
