import React from 'react';
import { CgClose } from 'react-icons/cg';
import { AiOutlineCloseCircle } from 'react-icons/ai';

type Props = {
  setdelete_modal: any;
  removeUser: any;
};

const DeleteModal: React.FC<Props> = ({ setdelete_modal, removeUser }) => {
  return (
    <div className='z-20 absolute bg-black/75 w-screen h-screen flex items-center justify-center top-0 left-0 '>
      <button
        onClick={() => setdelete_modal(false)}
        className='absolute top-10 right-12 text-white text-4xl'
      >
        <CgClose />
      </button>
      <div className='bg-white flex flex-col items-center p-20 rounded font-semibold'>
        <AiOutlineCloseCircle className='mb-8 text-red-500 text-6xl' />
        <h2 className='text-3xl capitalize mb-1'>DELETE USER?</h2>
        <p className='text-gray-500'>Are you sure you want to delete this user? </p>
        <p className='text-gray-500'>This action cannot be undone</p>
        <div className='flex justify-around mt-10 gap-2'>
          <button
            onClick={() => setdelete_modal(false)}
            className='bg-gray-200 text=gray-800 py-2 px-5 rounded font-bold text-sm shadow-lg hover:shadow-xl hover:bg-gray-300 duration-100'
          >
            CANCEL
          </button>

          <button
            onClick={() => removeUser()}
            className='uppercase bg-red-600 py-2 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-red-700 duration-100'
          >
            DELETE USER
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
