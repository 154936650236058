import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import type { InitialState } from './types/ProductTypes'

const api = process.env.REACT_APP_API

export const getAllProducts = createAsyncThunk(
  'products/get',
  async (filters: any): Promise<any> => {
    const response = await axios.get(
      `${api}/products-and-services?search=${filters.search}&page=${filters.page}&limit=${filters.limit}`
    )

    return response.data.products
  }
)

export const postProduct = createAsyncThunk(
  'products/post',
  async (data: any): Promise<any> => {
    const response = await axios.post(`${api}/products-and-services`, data)

    return response.data
  }
)

export const putProduct = createAsyncThunk(
  'products/put',
  async (data: any): Promise<any> => {
    const response = await axios.put(
      `${api}/products-and-services/${data.id}`,
      data.body
    )

    return response.data
  }
)

export const deleteProduct = createAsyncThunk(
  'products/delete',
  async (id: string): Promise<any> => {
    const response = await axios.delete(`${api}/products-and-services/${id}`)

    return response.data
  }
)

const initialState: InitialState = {
  error: false,
  pending: false,
  product_list: {
    results: [],
    total_pages: 0
  }
}

const ProductSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.product_list.results = action.payload
    })
  }
})

export default ProductSlice.reducer
