import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getProductData, getProjectData } from '../../store/DashboardSlice';
import { getUserTasks } from '../../store/UserSlice';
import Products from './Products';
import Projects from './Projects';
import Layout from '../Layout';
import Tasks from './Tasks';
import { getAllProjects } from '../../store/ProjectsSlice';

const Dashboard = () => {
  const dashboardData = useSelector((state: RootState) => state.dashboard);

  const tasks = useSelector((state: RootState) => state.user.user.tasks) || [];

  const projects =
    useSelector((state: RootState) => state.project.project_list.results).slice(
      0,
      6
    ) || [];

  // Safely parse localStorage data
  const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
  const userId = userLocalStorage ? userLocalStorage['id'] : '';

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const filters = {
      search: '',
    };
    dispatch(getProjectData());
    dispatch(getProductData());
    // Check if userId is not empty before dispatching getUserTasks
    if (userId) {
      dispatch(getUserTasks(userId));
    }
    dispatch(getAllProjects(filters));
  }, [dispatch, userId]);

  return (
    <Layout className='w-full bg-gray-50 flex flex-col items-center pt-10 pb-16'>
      <Projects project_data={dashboardData.projects.categories} />
      <Products product_list={dashboardData.products.list} />
      <Tasks tasks={tasks} projects={projects} />
    </Layout>
  );
};

export default Dashboard;
