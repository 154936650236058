import React, { useEffect } from 'react';
import { FaTasks } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getUserTasks } from '../../store/UserSlice';
import TaskList from './TaskList';
import Layout from "../Layout";

const Tasks = () => {
  const dispatch: AppDispatch = useDispatch();

  const tasks = useSelector((state: RootState) => state.user.user.tasks) || [];

  const userId = useSelector((state: RootState) => state.user.user.user_id);

  useEffect(() => {
    dispatch(getUserTasks(userId || ''));
  }, [dispatch, userId]);

  return (
    <Layout>
      <div className='w-full'>
        <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
          <h1 className='font-semibold flex items-center gap-3 uppercase py-2'>
            <span className={`text-orange-400`}>
              <FaTasks />
            </span>
            My Tasks
          </h1>
        </div>
        <TaskList tasks={tasks} />
      </div>
    </Layout>
  );
};

export default Tasks;
