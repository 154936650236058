import React from 'react';
import { Link } from 'react-router-dom';
import type { Project as IProject } from '../../store/types/ProjectTypes';

type PropTypes = {
  project: IProject;
};

const Project: React.FC<PropTypes> = ({ project }) => {
  return (
    <tr className='border-b w-full text-sm'>
      <td className='py-4 px-10'>{project.project_id}</td>
      <td className='py-4 px-10'>
        {project.customer?.first_name + ' ' + project.customer?.last_name}
      </td>
      <td className='py-4 px-10'>{project.date}</td>
      <td className='py-4 px-10'>
        {project.site_address.join(' ').slice(0, 8) + '...'}
      </td>
      <td className='py-4 px-10'>{project.utility}</td>
      <td className='py-4 px-10'>{project.phase}</td>
      <td className='py-4 px-10'>{project.status}</td>
      <td className='py-4 px-10 underline '>
        <Link
          className='ml-auto mt-5  text-xs font-semibold text-gray-500 hover:text-gray-700 hover:underline ease-in-out'
          to={`/projects/details/${project.id}`}
        >
          View
        </Link>
      </td>
    </tr>
  );
};

export default Project;
