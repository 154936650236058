import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { InitialState, Receipt } from './types/ReceiptTypes';

const api = process.env.REACT_APP_API;

export const getAllReceipts = createAsyncThunk(
  'receipts/get',
  async (filters: any): Promise<any> => {
    const response = await axios.get(
      `${api}/receipts?search=${filters.search}&page=${filters.page}&limit=${filters.limit}`
    );

    return response.data.receipts;
  }
);

export const postReceipt = createAsyncThunk(
  'receipts/post',
  async (data: Receipt): Promise<any> => {
    const response = await axios.post(`${api}/receipts`, data);

    return response.data;
  }
);

export const sendReceipt = createAsyncThunk(
  '/receipt/email',
  async (data: any): Promise<any> => {
    const response = await axios.post(
      `${api}/receipts/send-email/${data.id}`,
      data.data
    );

    return response.data;
  }
);

export const deleteReceipt = createAsyncThunk(
  'receipt/delete',
  async (id: string): Promise<any> => {
    const response = await axios.delete(`${api}/receipts/${id}`);

    return response.data;
  }
);

const initialState: InitialState = {
  receipt: {
    id: undefined,
    customer_name: '',
    address: [],
    email: '',
    receipt_no: '',
    payment_method: '',
    date: '',
    payment_reference: '',
    items: [],
  },
  pending: false,
  error: false,
  post_status: '',
  receipt_list: {
    results: [],
    total_pages: 0,
  },
};

const CustomerSlice = createSlice({
  name: 'receipt',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllReceipts.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getAllReceipts.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(getAllReceipts.fulfilled, (state, action) => {
      state.receipt_list.results = action.payload;
    });
    builder.addCase(postReceipt.pending, (state) => {
      state.post_status = 'PENDING';
    });
    builder.addCase(postReceipt.rejected, (state) => {
      state.post_status = 'FAILED';
    });
    builder.addCase(postReceipt.fulfilled, (state, action) => {
      state.post_status = 'SUCCESS';
    });
  },
});

export default CustomerSlice.reducer;
