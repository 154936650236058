import React from 'react';

type Props = {
    setdelete_modal: any;
    removeCustomer: any;
}

const DeleteModal: React.FC<Props> = ({setdelete_modal, removeCustomer}) => {
  return (
    <div className='bg-black/75 z-20 absolute top-0 left-0 h-full w-screen flex justify-center items-center'>
      <div className='bg-white rounded flex flex-col items-center p-10 w-1/2 text-center text-black'>
        <p className='bg-orange-200 rounded py-1 px-2'>
          <b>WARNING: </b>Deleting the customer will result in deleting all
          associated projects, invoices, etc.
        </p>
        <p className='my-3'>Are you sure you want to continute?</p>
      <div className='flex items-center justify-center space-x-3'>
        <button onClick={() => removeCustomer()} className='rounded px-3 py-2 text-white font-semibold bg-green-600'>CONFIRM</button>
        <button onClick={() => setdelete_modal(false)} className='rounded px-3 py-2 text-white font-semibold bg-red-600'>CANCEL</button>
      </div>
      </div>
    </div>
  );
};

export default DeleteModal;
