import {
    FaCheckDouble,
    FaFileAlt,
    FaFileInvoice,
    FaHome,
    FaProjectDiagram,
    FaReceipt,
    FaSolarPanel,
    FaTasks,
    FaUser,
    FaUserFriends,
} from 'react-icons/fa';

export const links = [
    { name: 'Dashboard', path: '/dashboard', icon: <FaHome /> },
    { name: 'Customers', path: '/customers', icon: <FaUserFriends /> },
    { name: 'Projects', path: '/projects', icon: <FaProjectDiagram /> },
    { name: 'Receipts', path: '/receipts', icon: <FaReceipt /> },
    { name: 'My Tasks', path: '/my-tasks', icon: <FaTasks /> },
    { name: 'Activity Logs', path: '/activity-logs', icon: <FaCheckDouble /> },
    { name: 'Users', path: '/users', icon: <FaUser /> },
    {
        name: 'Products & Services',
        path: '/products-and-services',
        icon: <FaSolarPanel />,
    },
    { name: 'Invoices', path: '/invoices', icon: <FaFileInvoice /> },
    { name: 'BOQs', path: '/boqs', icon: <FaFileAlt /> },
];