import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch } from '../../store';
import { deleteBoq, getAllBoqs, putBoq } from '../../store/BoqSlice';
import Options from './Options';

type Props = {
  boq: any;
};

type BoqProps = {
  id: string;
};

const Boq: React.FC<Props> = ({ boq }) => {
  const [filters] = useState({
    page: 0,
    limit: 10,
  });

  const [options, setoptions] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [approveModal, setapproveModal] = useState(false);

  const user = JSON.parse(localStorage.getItem('user') || '');

  const dispatch: AppDispatch = useDispatch();

  const removeBoq = async (id: string) => {
    await dispatch(deleteBoq(id));
    dispatch(getAllBoqs(filters));
    setdeleteModal(false);
  };

  const updateBoq = async (id: string) => {
    const data = {
      id: id,
      data: {
        status: true,
      },
    };

    await dispatch(putBoq(data));
    await dispatch(getAllBoqs(filters));
    setapproveModal(false);
  };

  const DeleteModal: React.FC<BoqProps> = ({ id }) => {
    return (
      <div>
        <button
          onClick={() => removeBoq(id)}
          className='rounded mx-1 bg-red-600 border border-red-800 text-white font-semibold text-xs p-1'
        >
          Delete
        </button>
        <button
          onClick={() => setdeleteModal(false)}
          className='rounded mx-1 bg-green-600 border border-green-800 text-white font-semibold text-xs p-1'
        >
          Cancel
        </button>
      </div>
    );
  };

  const ApproveModal: React.FC<BoqProps> = ({ id }) => {
    return (
      <div>
        <button
          onClick={() => updateBoq(id)}
          className='rounded bg-green-600 border text-white font-semibold text-xs p-1'
        >
          Confirm
        </button>
        <button
          onClick={() => setapproveModal(false)}
          className='rounded bg-red-600 border text-white font-semibold text-xs p-1'
        >
          Cancel
        </button>
      </div>
    );
  };

  return (
    <tr key={boq.project_id} className='relative text-sm'>
      <td className='py-4 px-10 hover:underline'>
        <Link to={`/projects/details/${boq.project_id}`}>
          {boq.project.project_id}
        </Link>
      </td>
      <td className='py-4 px-10'>{boq.boq_template.boq_name}</td>
      <td className={'font-semibold py-4 px-10 flex items-center'}>
        {boq.status ? (
          <p className='text-green-600'>
            Approved <span className='text-black'>({boq.user.user_name})</span>
          </p>
        ) : (
          <p className='text-red-600'>Not Approved</p>
        )}
        {approveModal ? (
          <ApproveModal id={boq.id} />
        ) : (
          user.admin &&
          boq.status === false && (
            <button
              onClick={() => setapproveModal(true)}
              className='bg-green-600 py-1 px-2 rounded text-white ml-5 font-semibold text-xs'
            >
              Approve
            </button>
          )
        )}
      </td>
      <td className='py-4 px-10'>
        {deleteModal ? (
          <DeleteModal id={boq.id} />
        ) : (
          <button
            className='rounded-full hover:bg-gray-100 duration-200 p-1'
            onClick={() => setoptions(true)}
            onBlur={() =>
              setTimeout(() => {
                setoptions(false);
              }, 200)
            }
          >
            <BsThreeDotsVertical />
          </button>
        )}
      </td>
      {options && <Options setdeleteModal={setdeleteModal} />}
    </tr>
  );
};

export default Boq;
