import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { getAllProjects } from '../../store/ProjectsSlice';
import { getAllProducts } from '../../store/ProductSlice';
import { FaPlus } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import Select from 'react-select';
import { postInvoice } from '../../store/InvoiceSlice';
import { dateString, generateUniqueId } from '../../utils/unique-no';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';
import Layout from '../Layout';

const NewInvoice = () => {
  const [filters] = useState({
    search: '',
    page: 0,
    limit: 10,
  });

  const navigate = useNavigate();

  type Invoice = {
    project_id: string;
    invoice_no: string;
    user_id: string;
    date: string;
    items: {
      product_id: string;
      product_name: string;
      quantity: number;
      buying_price: number;
      price: number;
    }[];
  };

  const invoice_no = generateUniqueId('STI');

  const [project, setproject] = useState<any>({
    site_address: [],
    email_address: '',
    payment_method: '',
    customer: '',
  });

  const [invoice, setinvoice] = useState<Invoice>({
    project_id: '',
    date: dateString(),
    invoice_no: invoice_no,
    items: [],
    user_id: String(JSON.parse(localStorage.getItem('user') || '').id)
  });

  const [item, setitem] = useState({
    product_id: '',
    product_name: '',
    quantity: 0,
    buying_price: 0,
    price: 0,
  });

  const projects = useSelector(
    (state: RootState) => state.project.project_list.results
  );

  const products = useSelector(
    (state: RootState) => state.product.product_list.results
  );

  const projectOptions = projects?.map((project) => ({
    label: project.project_id,
    value: project,
  }));

  const productOptions = products.map((product) => ({
    label: product.product_name,
    value: product,
  }));

  const dispatch: AppDispatch = useDispatch();

  const addItem = () => {
    if (
      item.quantity !== 0 &&
      invoice.items.filter((e) => e.product_id === item.product_id).length === 0
    ) {
      setinvoice({ ...invoice, items: [...invoice.items, item] });
      setitem({
        ...item,
        quantity: 0,
        buying_price: 0,
        price: 0,
      });
    }
  };

  const removeItem = (id: string) => {
    setinvoice({
      ...invoice,
      items: invoice.items.filter((item) => item.product_id !== id),
    });
  };

  const submitInvoice = async (e: any) => {
    e.preventDefault();

    const data = invoice;

    await dispatch(postInvoice(data));

    navigate('/invoices');
  };

  useEffect(() => {
    dispatch(getAllProjects(filters));
    dispatch(getAllProducts(filters));
  }, [dispatch, filters]);

  return (
    <Layout>
      <div className='w-full flex flex-col items-center'>
        <div className='flex items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
          <div className='flex items-center'>
            <button
              onClick={() => navigate('/invoices')}
              className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
            >
              <FaArrowCircleLeft />
            </button>
            <h1 className='font-semibold py-2'>NEW INVOICE</h1>
          </div>
        </div>

        <form
          onSubmit={(e) => submitInvoice(e)}
          className='shadow-lg rounded xl:w-4/5 p-10 xl:my-10 flex flex-col w-full'
        >
          <h2 className='font-semibold capitalize mb-1 text-lg'>
            Create Invoice
          </h2>
          <p className='font-semibold capitalize mb-4 text-sm text-gray-400'>
            Fill the form to create new invoice
          </p>

          <div className='flex flex-col lg:flex-row justify-evenly gap-5'>
            <div className='w-full'>
              <div className='flex flex-col mb-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Search Project ID:
                </label>
                <Select
                  onChange={(e) => {
                    setinvoice({ ...invoice, project_id: e?.value.id || '' });
                    setproject({
                      ...project,
                      site_address: e?.value.site_address,
                      email_address: e?.value.email_address,
                      customer:
                        e?.value.customer?.first_name +
                        ' ' +
                        e?.value.customer?.last_name,
                      payment_method: e?.value.payment_option,
                    });
                  }}
                  options={projectOptions}
                />
              </div>
              <div className='flex flex-col mb-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Invoice No:
                </label>
                <input
                  required
                  readOnly
                  value={invoice_no}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                />
              </div>
              <div className='flex flex-col'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Customer Name:
                </label>
                <input
                  required
                  readOnly
                  placeholder='Customer Name'
                  value={project.customer}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                />
              </div>
              <div className='flex flex-col'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Payment Method:
                </label>
                <input
                  required
                  readOnly
                  placeholder='Payment Method'
                  value={project.payment_method}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                />
              </div>
            </div>

            {/* Column 2 */}
            <div className='w-full'>
              <div className='flex flex-col'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Address Line 1:
                </label>
                <input
                  required
                  readOnly
                  placeholder='Address Line 1'
                  value={project.site_address[0]}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                />
              </div>
              <div className='flex flex-col'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Address Line 2:
                </label>
                <input
                  required
                  readOnly
                  placeholder='Address Line 2'
                  value={project.site_address[1]}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                />
              </div>
              <div className='flex flex-col'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Address Line 3:
                </label>
                <input
                  required
                  readOnly
                  placeholder='Address Line 3'
                  value={project.site_address[2]}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                />
              </div>
              <div className='flex flex-col'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  E-mail address:
                </label>
                <input
                  required
                  readOnly
                  placeholder='E-mail address'
                  value={project.email_address}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                />
              </div>
            </div>
          </div>

          <hr className='my-8 border border-gray-300 border-dotted' />

          <div className='w-full mb-5'>
            <h2 className='font-semibold mb-4'>Invoice Items:</h2>

            {/* Table Items */}
            {invoice.items.length > 0 && (
              <div className='rounded border p-2 w-full my-2'>
                <div className='grid grid-cols-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider mb-3'>
                  <p className='text-gray-500 font-semibold'>Product/Service</p>
                  <p className='text-gray-500 font-semibold'>Qty</p>
                  <p className='text-gray-500 font-semibold'>Price</p>
                </div>
                {invoice.items.map((item, idx) => (
                  <div key={idx} className='grid grid-cols-3 text-center'>
                    <p className='text-sm pt-2 '>{item.product_name}</p>
                    <p className='text-sm pt-2 '>{item.quantity}</p>
                    <div className='flex items-center justify-center relative'>
                      <p className='text-sm mx-5 '>
                        {item.price.toLocaleString()}
                      </p>
                      <button
                        className='p-1 rounded text-red-500 hover:bg-red-400 hover:text-white font-bold absolute right-5 top-50 duration-200'
                        onClick={() => removeItem(item.product_id)}
                      >
                        <CgClose />
                      </button>
                    </div>
                  </div>
                ))}
                <p className='mt-5 text-sm font-semibold text-right mr-5'>
                  <hr className=' mb-3 border border-gray-300 border-dotted' />
                  <span>Total: </span>
                  Rs.{' '}
                  {invoice.items
                    .reduce((a: any, c: any) => a + c.price, 0)
                    .toLocaleString()}
                </p>
              </div>
            )}

            <div className='flex flex-col xl:flex-row items-end justify-center gap-4'>
              <div className='flex flex-col w-full'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Product / Service:
                </label>

                <Select
                  onChange={(e) =>
                    setitem({
                      ...item,
                      product_id: e?.value.id || '',
                      product_name: e?.value.product_name || '',
                    })
                  }
                  className='w-full text-sm rounded'
                  options={productOptions}
                />
              </div>

              <div className='flex flex-col w-full'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Quantity:{' '}
                </label>

                <input
                  value={item.quantity}
                  onChange={(e) =>
                    setitem({ ...item, quantity: Number(e.target.value) })
                  }
                  className='border border-gray-400 rounded p-2'
                  type='number'
                />
              </div>
              <div className='flex flex-col w-full'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Buying Price (Rs.):{' '}
                </label>
                <input
                  value={item.buying_price}
                  onChange={(e) =>
                    setitem({ ...item, buying_price: Number(e.target.value) })
                  }
                  className='border border-gray-400 rounded p-2'
                  type='number'
                />
              </div>
              <div className='flex flex-col w-full'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Price (Rs.):
                </label>
                <input
                  value={item.price}
                  onChange={(e) =>
                    setitem({ ...item, price: Number(e.target.value) })
                  }
                  className='border border-gray-400 rounded p-2 '
                  type='number'
                />
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  addItem();
                }}
                disabled={item.quantity === 0}
                className='bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 disabled:opacity-50 disabled:bg-green-600'
              >
                <FaPlus />
              </button>
            </div>
          </div>
          <button
            disabled={invoice.items.length === 0}
            className='bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 w-full mt-auto'
          >
            SUBMIT
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default NewInvoice;
