import React from 'react';
import Project from './Project';
import type { Project as IProject } from '../../store/types/ProjectTypes';

type Props = {
  projects: IProject[];
};

const headerItems = [
  'Project ID',
  'Customer',
  'Date',
  'Location',
  'Utility',
  'Phase',
  'Project Status',
  'Details',
];

const ProjectList: React.FC<Props> = ({ projects }) => {
  return (
    <div className='w-full px-10 py-3 mt-5'>
      <table className='w-full leading-normal shadow-md rounded-lg'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <Project key={project.id} project={project} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectList;
