import React, { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { getAllProducts, postProduct } from '../../store/ProductSlice';

type Props = {
  setnew_modal: any;
};

const NewProduct: React.FC<Props> = ({ setnew_modal }) => {
  const [filters] = useState({
    search: '',
    page: 0,
    limit: 10,
  });

  const [product, setproduct] = useState({
    product_name: '',
    description: '',
    type: '',
  });

  const [type_warning, settype_warning] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const submitProduct = async (e: any) => {
    e.preventDefault();
    if (!product.type) {
      return settype_warning(true);
    } else {
      settype_warning(false);
      await dispatch(postProduct(product));
      dispatch(getAllProducts(filters));
      return setnew_modal(false);
    }
  };

  return (
    <div className='z-20 bg-black/75 h-full w-screen fixed top-0 left-0 flex justify-center items-center'>
      <button
        onClick={() => setnew_modal(false)}
        className='text-4xl absolute top-10 right-14 text-white'
      >
        <CgClose />
      </button>
      <div className='bg-white rounded p-8 w-5/6  md:w-3/5 lg:2/5 xl:w-4/12'>
        <h1 className='mb-5 font-semibold uppercase'>
          Add New Product/Service
        </h1>
        <form onSubmit={(e) => submitProduct(e)}>
          <div className='flex flex-col mb-3'>
            <label className='my-1 text-sm text-gray-500 font-semibold'>
              Title:{' '}
            </label>
            <input
              required
              onChange={(e) =>
                setproduct({ ...product, product_name: e.target.value })
              }
              className='border py-3 text-sm px-2 rounded'
              type='text'
              placeholder='Product/Service title'
            />
          </div>
          <div className='flex flex-col mb-3'>
            <label className='my-1 text-sm text-gray-500 font-semibold'>
              Description (optional):{' '}
            </label>
            <input
              onChange={(e) =>
                setproduct({ ...product, description: e.target.value })
              }
              className='border py-3 text-sm px-2 rounded'
              type='text'
              placeholder='Description (optional)'
            />
          </div>
          <div className='flex flex-col mb-3'>
            <label className='my-1 text-sm text-gray-500 font-semibold'>
              Type:
            </label>
            <select
              onChange={(e) => setproduct({ ...product, type: e.target.value })}
              className={`py-1 px-2 border py-3 text-sm px-2 rounded
              ${type_warning && 'border-4 border-red-600'}`}
            >
              <option className='my-1 text-gray-500' value=''>
                --SELECT--
              </option>
              <option className='my-1 text-gray-500' value='Product'>
                Product
              </option>
              <option className='my-1 text-gray-500' value='Service'>
                Service
              </option>
            </select>
          </div>
          <button
            className={`uppercase mt-4 bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 w-full mt-5 `}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewProduct;
