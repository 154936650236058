import React from "react";
import { CgClose } from "react-icons/cg";

type Props = {
  setinstallationImageModal: any;
  images: string[];
};

const InstallationImages: React.FC<Props> = ({
  setinstallationImageModal,
  images,
}) => {
  return (
    <div className="z-20 bg-black/75 fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
      <button
        onClick={() => setinstallationImageModal(false)}
        className="text-4xl text-white absolute top-10 right-16"
      >
        <CgClose />
      </button>
      <div className="rounded bg-white p-10 w-2/3">
        <h4 className="font-semibold text-lg mb-4">INSTALLATION IMAGES</h4>
        <div className="flex flex-wrap justify-center">
          {images.map((image, idx) => (
            <a
              key={idx}
              className="m-2"
              href={image}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-48 h-28 object-cover object-top"
                src={image}
                alt=""
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InstallationImages;
