import React from 'react';
import { footerHeight } from '../utils/constants';
// Images
import nugoLogo from '../images/nugo-logo.svg';

const Footer = () => {
  return (
    <div
      className='bg-orange-200 border-gray-200 border-t-3  relative px-6 text-black text-xs flex items-center justify-between z-10'
      style={{
        height: `${footerHeight}px`,
      }}
    >
      <p>© Copyright {new Date().getFullYear()} Sunterra (Pvt) Ltd.</p>
      <a
        href='https://www.nugo.lk'
        target='blank'
        rel='nofollow'
        className='flex items-center gap-2 '
      >
        <img src={nugoLogo} alt='nugo' className='h-4 w-4' />
      </a>
    </div>
  );
};

export default Footer;
