import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProjectList from './ProjectList';
import type { RootState, AppDispatch } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { getAllProjects } from '../../store/ProjectsSlice';
import { getAllUsers } from '../../store/UserSlice';
import { FaSearch, FaProjectDiagram } from 'react-icons/fa';
import Layout from "../Layout";

const Projects = () => {
  const [filters, setfilters] = useState({
    search: '',
    page: 0,
    limit: 10,
  });

  const [search, setsearch] = useState('');

  const projects =
    useSelector((state: RootState) => state.project.project_list) || [];

  const dispatch: AppDispatch = useDispatch();

  const total_pages: number[] = Array.from(
    Array(Math.round(projects.total_pages)).keys()
  );

  let pages: number[] = [];

  if (filters.page > 0) {
    pages = total_pages.slice(filters.page - 1, filters.page + 2);
  } else {
    pages = total_pages.slice(0, 3);
  }

  useEffect(() => {
    dispatch(getAllProjects(filters));
    dispatch(getAllUsers());
  }, [filters, dispatch]);

  const applyFilters = (e?: any) => {
    e?.preventDefault();
    setfilters({ ...filters, search: search });
  };

  return (
    <Layout>
      <div className='w-full relative'>
        <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
          <h1 className='font-semibold flex items-center gap-3 uppercase'>
            <span className={`text-orange-400`}>
              <FaProjectDiagram />
            </span>
            Projects
          </h1>
          <div className='flex items-center'>
            <form className='flex items-center' onSubmit={(e) => applyFilters(e)}>
              <input
                onChange={(e) => setsearch(e.target.value)}
                type='text'
                placeholder='Project ID or Status'
                className='rounded-l py-2 px-3 border border-gray-400 border-r-0 text-sm h-10'
              />
              <button
                type='submit'
                className='p-3 border border-gray-400 rounded-r h-10 hover:bg-gray-50 duration-100'
              >
                <FaSearch />
              </button>
            </form>
            <Link
              className='bg-green-600 py-2 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 ml-5'
              to='/projects/new-project'
            >
              New Project
            </Link>
          </div>
        </div>
        <ProjectList projects={projects.results} />
        <div className='flex items-center justify-end px-10 py-3'>
          <div className='flex items-center text-sm'>
            <p>Items per page: </p>
            <select
              className='border rounded px-2 py-1 ml-1 mr-2'
              onChange={(e) => {
                setfilters({ ...filters, limit: Number(e.target.value) });
              }}
            >
              <option value='10'>10</option>
              <option value='15'>15</option>
              <option value='20'>20</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => {
                filters.page > 0 &&
                  setfilters({ ...filters, page: filters.page - 1 });
              }}
              className='py-1 px-3 border rounded m-1 hover:bg-orange-100 duration-200'
            >
              {'<'}
            </button>
            {pages.map((page) => (
              <button
                onClick={() => {
                  setfilters({ ...filters, page: page });
                }}
                className={`py-1 px-3 border rounded m-1 hover:bg-orange-100 duration-200 ${page === filters.page && 'bg-orange-100'
                  }`}
                key={page}
              >
                {page + 1}
              </button>
            ))}
            <button
              onClick={() => {
                filters.page < total_pages.length - 1 &&
                  setfilters({ ...filters, page: filters.page + 1 });
              }}
              className='py-1 px-3 border rounded m-1 hover:bg-orange-100 duration-200'
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
