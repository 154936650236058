import React from 'react';
import { Log } from '../../store/types/LogTypes';

type Props = {
  logs: Log[] | undefined;
  projectId: string | undefined;
  status: string;
  statuses: string[];
};

const LogsModal: React.FC<Props> = ({ logs, projectId, status, statuses }) => {
  const headerItems = [
    'Date Completed',
    'Task Completed',
    'Duration [Days]',
    'Completed By',
    'Notes',
  ];

  const current_status = statuses.indexOf(status);

  const total_statuses = statuses.length - 1;

  const progress = Math.round((current_status / total_statuses) * 100);

  return (
    <div className='w-full'>
      <h4 className='font-semibold my-4'>PROJECT ACTIVITY - {projectId}</h4>

      <div className='h-10 my-4 bg-green-100 rounded'>
        <div
          style={{ width: progress + '%' }}
          className='h-full bg-green-500 rounded font-semibold items-center relative'
        >
          <p className='absolute top-2 left-2 w-max text-black text-sm px-2 rounded'>
            Progress: {current_status} of {total_statuses} tasks complete.
          </p>
        </div>
      </div>

      <table className='w-full'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {logs?.map((log: Log) => (
            <tr key={log.id} className='border-b text-sm'>
              <td className='py-4 px-10'>{log.createdAt?.split('T')[0]}</td>
              <td className='py-4 px-10'>{log.status}</td>
              <td className='py-4 px-10'>{log.duration}</td>
              <td className='py-4 px-10'>{log.user?.user_name}</td>
              <td className='py-4 px-10'>{log.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LogsModal;
