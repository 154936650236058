import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { postCustomer } from '../../store/CustomerSlice';
import type { Customer } from '../../store/types/CustomerTypes';
import { FaArrowCircleLeft } from 'react-icons/fa';
import Layout from "../Layout";

const NewCustomer = () => {
  const [customer, setcustomer] = useState<Customer>({
    first_name: '',
    last_name: '',
    email_address: '',
    contact_number: '',
    address: [],
  });

  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  const nameRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(postCustomer(customer));
    navigate('/customers');
  };

  useEffect(() => {
    nameRef.current?.focus();
  }, []);

  return (
    <Layout>
      <div className='w-full flex flex-col items-center'>
        <div className='flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
          {/* Back Button */}
          <div className='flex items-center'>
            <button
              onClick={() => navigate('/customers')}
              className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
            >
              <FaArrowCircleLeft />
            </button>
            <h1 className='font-semibold py-2'>NEW CUSTOMER</h1>
          </div>
        </div>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className='shadow-lg rounded xl:w-4/5 p-10 xl:my-10 flex flex-col w-full'
        >
          <h2 className='font-semibold capitalize mb-1 text-lg'>
            Add New Customer
          </h2>
          <p className='font-semibold capitalize mb-4 text-sm text-gray-400'>
            Please enter customer information
          </p>

          <div className='flex flex-col lg:flex-row justify-between'>
            <div className='w-full p-2 '>
              <div className='flex flex-col mb-3'>
                <label
                  className='my-1 text-sm text-gray-400 font-semibold'
                  htmlFor='first-name'
                >
                  First Name:
                </label>
                <input
                  required
                  placeholder='First Name'
                  onChange={(e) =>
                    setcustomer({ ...customer, first_name: e.target.value })
                  }
                  ref={nameRef}
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                  name='first-name'
                />
              </div>
              <div className='flex flex-col mb-3'>
                <label
                  className='my-1 text-sm text-gray-400 font-semibold'
                  htmlFor='last-name'
                >
                  Last Name:
                </label>
                <input
                  required
                  placeholder='Last Name'
                  onChange={(e) =>
                    setcustomer({ ...customer, last_name: e.target.value })
                  }
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                  name='last-name'
                />
              </div>
              <div className='flex flex-col mb-3'>
                <label
                  className='my-1 text-sm text-gray-400 font-semibold'
                  htmlFor='contact-no'
                >
                  Contact No:
                </label>
                <input
                  required
                  placeholder='Contact No'
                  onChange={(e) =>
                    setcustomer({ ...customer, contact_number: e.target.value })
                  }
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                  name='contact-no'
                />
              </div>
              <div className='flex flex-col'>
                <label
                  className='my-1 text-sm text-gray-400 font-semibold'
                  htmlFor='email'
                >
                  E-mail Address:
                </label>
                <input
                  placeholder='Customer E-mail'
                  required
                  onChange={(e) =>
                    setcustomer({ ...customer, email_address: e.target.value })
                  }
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                  name='email'
                />
              </div>
            </div>
            <div className='w-full p-2 flex flex-col'>
              <div className='flex flex-col mb-3'>
                <label
                  className='my-1 text-sm text-gray-400 font-semibold'
                  htmlFor='line-1'
                >
                  Address Line 1:
                </label>
                <input
                  placeholder='Address Line 1'
                  required
                  onChange={(e) =>
                    setcustomer({
                      ...customer,
                      address: [e.target.value, ...customer.address.slice(1)],
                    })
                  }
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                  name='line-1'
                />
              </div>
              <div className='flex flex-col mb-3'>
                <label
                  className='my-1 text-sm text-gray-400 font-semibold'
                  htmlFor='line-2'
                >
                  Address Line 2:
                </label>
                <input
                  placeholder='Address Line 2'
                  onChange={(e) =>
                    setcustomer({
                      ...customer,
                      address: [
                        ...customer.address.slice(0, 1),
                        e.target.value,
                        ...customer.address.slice(2),
                      ],
                    })
                  }
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                  name='line-2'
                />
              </div>
              <div className='flex flex-col mb-3'>
                <label
                  className='my-1 text-sm text-gray-400 font-semibold'
                  htmlFor='line-3'
                >
                  Address Line 3:
                </label>
                <input
                  placeholder='Address Line 3'
                  onChange={(e) =>
                    setcustomer({
                      ...customer,
                      address: [...customer.address.slice(0, 2), e.target.value],
                    })
                  }
                  className='border py-3 text-sm px-2 rounded'
                  type='text'
                  name='line-3'
                />
              </div>
              <button className='bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 w-full mt-auto'>
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default NewCustomer;
