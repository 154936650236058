import React, { useState } from 'react';
import type { Receipt as IReceipt } from '../../store/types/ReceiptTypes';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Options from './Options';
import ReceiptEmail from './ReceiptEmail';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { deleteReceipt, getAllReceipts } from '../../store/ReceiptSlice';

type Props = {
  receipt: IReceipt;
};

type DeleteProps = {
  id: string;
};

const Receipt: React.FC<Props> = ({ receipt }) => {
  const [options, setoptions] = useState(false);
  const [emailModal, setemailModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);

  const { customer_name, receipt_no, date, email } = receipt;
  const total = receipt.items.reduce((a: any, c: any) => a + c.price, 0);

  const filters = {
    search: '',
    page: 0,
    limit: 10,
  };

  const dispatch: AppDispatch = useDispatch();

  const removeReceipt = async (id: string) => {
    await dispatch(deleteReceipt(id));
    setdeleteModal(false);
    dispatch(getAllReceipts(filters));
  };

  const DeleteModal: React.FC<DeleteProps> = ({ id }) => {
    return (
      <div>
        <button
          onClick={() => removeReceipt(id)}
          className='rounded mx-1 bg-red-600 border border-red-800 text-white font-semibold text-xs p-1'
        >
          Delete
        </button>
        <button
          onClick={() => setdeleteModal(false)}
          className='rounded mx-1 bg-green-600 border border-green-800 text-white font-semibold text-xs p-1'
        >
          Cancel
        </button>
      </div>
    );
  };

  return (
    <tr className='border-b relative text-sm' key={receipt.id}>
      <td className='py-4 px-10'>{receipt_no}</td>
      <td className='py-4 px-10'>{date}</td>
      <td className='py-4 px-10'>{customer_name}</td>
      <td className='py-4 px-10'>{email}</td>
      <td className='py-4 px-10'>Rs. {total}</td>
      <td className='py-4 px-10'>
        {deleteModal ? (
          <DeleteModal id={receipt.id} />
        ) : (
          <button
            className='rounded-full hover:bg-gray-100 duration-200 p-1'
            onClick={() => setoptions(true)}
            onBlur={() =>
              setTimeout(() => {
                setoptions(false);
              }, 200)
            }
          >
            <BsThreeDotsVertical />
          </button>
        )}
      </td>
      {options && <Options id={receipt.id} setdeleteModal={setdeleteModal} />}
      {emailModal && (
        <ReceiptEmail
          setemailModal={setemailModal}
          id={receipt.id}
          email={receipt.email}
        />
      )}
    </tr>
  );
};

export default Receipt;
