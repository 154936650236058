import React from 'react';
import { Invoice as IInvoice } from '../../store/types/InvoiceTypes';
import Invoice from './Invoice';

type Props = {
  invoices: IInvoice[];
};

const headerItems = [
  'Invoice No',
  'Date',
  'Project ID',
  'Created By',
  'Options',
];

const InvoiceList: React.FC<Props> = ({ invoices }) => {
  return (
    <div className='w-full px-10 py-3 mt-5'>
      <table className='w-full leading-normal shadow-md rounded-lg'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice: IInvoice) => (
            <Invoice key={invoice.id} invoice={invoice} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceList;
