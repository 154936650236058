import React from 'react';
import { User as TUser } from '../../store/types/UserTypes';
import User from './User';

type Props = {
  users: TUser[];
};

const headerItems = [
  'First Name',
  'Last Name',
  'Username',
  'User Type',
  'E-mail',
  'Added Date',
  'Details',
];

const UserList: React.FC<Props> = ({ users }) => {
  return (
    <div className='w-full px-10 py-3 my-5'>
      <table className='w-full leading-normal shadow-md rounded-lg'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <User key={user.id} user={user} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
