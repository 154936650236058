import React, { useState, useEffect } from 'react';
import { FaEdit, FaSave, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import {
  deleteCustomer,
  getOneCustomer,
  updateCustomer,
} from '../../store/CustomerSlice';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteModal from './DeleteModal';
import { footerHeight, navbarHeight } from '../../utils/constants';

const CustomerDetails = () => {
  const customer = useSelector((state: RootState) => state.customer.customer);

  const [customer_details, setcustomer_details] = useState(customer);
  const [read_only, setread_only] = useState(true);
  const [delete_modal, setdelete_modal] = useState(false);

  const { id } = useParams();

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const submitUpdates = async () => {
    await dispatch(updateCustomer(customer_details));

    setread_only(true);
  };

  const removeCustomer = async () => {
    await dispatch(deleteCustomer(customer.id));
    navigate('/customers');
  };

  useEffect(() => {
    dispatch(getOneCustomer(id));
  }, [dispatch, id]);

  return (
    <div
      className='flex flex-col justify-center items-center w-full overflow-y-scroll'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex justify-between items-center w-full px-10'>
        <h3 className='font-semibold'>CUSTOMER DETAILS</h3>
        <div className='flex items-center justify-between space-x-1'>
          {read_only ? (
            <button
              onClick={() => setread_only(false)}
              className='text-md text-white bg-blue-600 rounded p-2'
            >
              <FaEdit />
            </button>
          ) : (
            <button
              onClick={() => submitUpdates()}
              className='text-md text-white bg-green-600 rounded p-2'
            >
              <FaSave />
            </button>
          )}
          <button
            onClick={() => setdelete_modal(true)}
            className='text-md text-white bg-red-600 rounded p-2'
          >
            <FaTrash />
          </button>
        </div>
      </div>
      <div className='bg-white rounded p-10 relative w-1/2'>
        <div className='flex flex-col mx-2 my-4'>
          <label>First Name : </label>
          {read_only ? (
            <p className='border-b px-3 py-2 rounded'>{customer.first_name}</p>
          ) : (
            <input
              className='border border-gray-400 px-3 py-2 rounded focus:border-none'
              readOnly={read_only}
              type='text'
              defaultValue={customer.first_name}
              onChange={(e) =>
                setcustomer_details({
                  ...customer_details,
                  first_name: e.target.value,
                })
              }
            />
          )}
        </div>
        <div className='flex flex-col mx-2 my-4'>
          <label>Last Name : </label>
          {read_only ? (
            <p className='border-b px-3 py-2 rounded'>{customer.last_name}</p>
          ) : (
            <input
              className='border border-gray-400 px-3 py-2 rounded focus:border-none'
              readOnly={read_only}
              type='text'
              defaultValue={customer.last_name}
              onChange={(e) =>
                setcustomer_details({
                  ...customer_details,
                  last_name: e.target.value,
                })
              }
            />
          )}
        </div>
        <div className='flex flex-col mx-2 my-4'>
          <label>E-mail address : </label>
          {read_only ? (
            <p className='border-b px-3 py-2 rounded'>
              {customer.email_address}
            </p>
          ) : (
            <input
              className='border border-gray-400 px-3 py-2 rounded focus:border-none'
              readOnly={read_only}
              type='text'
              defaultValue={customer.email_address}
              onChange={(e) =>
                setcustomer_details({
                  ...customer_details,
                  email_address: e.target.value,
                })
              }
            />
          )}
        </div>
        <div className='flex flex-col mx-2 my-4'>
          <label>Phone : </label>
          {read_only ? (
            <p className='border-b px-3 py-2 rounded'>
              {customer.contact_number}
            </p>
          ) : (
            <input
              className='border border-gray-400 px-3 py-2 rounded focus:border-none'
              readOnly={read_only}
              type='text'
              defaultValue={customer.contact_number}
              onChange={(e) =>
                setcustomer_details({
                  ...customer_details,
                  contact_number: e.target.value,
                })
              }
            />
          )}
        </div>
        <div className='flex flex-col mx-2 my-4'>
          <label>Address Line 1 : </label>
          {read_only ? (
            <p>{customer.address && customer.address[0]}</p>
          ) : (
            <input
              className='border border-gray-400
             px-3 py-2 rounded focus:border-none'
              readOnly={read_only}
              type='text'
              defaultValue={customer.address && customer.address[0]}
              onChange={(e) =>
                setcustomer_details({
                  ...customer_details,
                  address: [
                    e.target.value,
                    ...customer_details.address.slice(1),
                  ],
                })
              }
            />
          )}
        </div>
        <div className='flex flex-col mx-2 my-4'>
          <label>Address Line 2 : </label>
          {read_only ? (
            <p>{customer.address && customer.address[1]}</p>
          ) : (
            <input
              className={`${
                read_only ? 'border-b' : 'border border-gray-400'
              } px-3 py-2 rounded focus:border-none`}
              readOnly={read_only}
              type='text'
              defaultValue={customer.address && customer.address[1]}
              onChange={(e) =>
                setcustomer_details({
                  ...customer_details,
                  address: [
                    ...customer_details.address.slice(0, 1),
                    e.target.value,
                    ...customer_details.address.slice(2),
                  ],
                })
              }
            />
          )}
        </div>
        <div className='flex flex-col mx-2 my-4'>
          <label>Address Line 3 : </label>
          {read_only ? (
            <p>{customer.address && customer.address[2]}</p>
          ) : (
            <input
              className={`${
                read_only ? 'border-b' : 'border border-gray-400'
              } px-3 py-2 rounded focus:border-none`}
              readOnly={read_only}
              type='text'
              defaultValue={customer.address && customer.address[2]}
              onChange={(e) =>
                setcustomer_details({
                  ...customer_details,
                  address: [
                    ...customer_details.address.slice(0, 2),
                    e.target.value,
                  ],
                })
              }
            />
          )}
        </div>
      </div>
      {delete_modal && (
        <DeleteModal
          setdelete_modal={setdelete_modal}
          removeCustomer={removeCustomer}
        />
      )}
    </div>
  );
};

export default CustomerDetails;
