import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import type { InitialState, Customer } from './types/CustomerTypes';

const api = process.env.REACT_APP_API;

export const getAllCustomers = createAsyncThunk(
  'customers/get',
  async (filters: any): Promise<any> => {
    const response = await axios.get(
      `${api}/customers?search=${filters.search}&page=${filters.page}&limit=${filters.limit}`
    );

    return response.data;
  }
);

export const getOneCustomer = createAsyncThunk(
  'customers/get-one',
  async (customerId: string | undefined): Promise<any> => {
    const response = await axios.get(`${api}/customers/${customerId}`);

    return response.data;
  }
);

export const postCustomer = createAsyncThunk(
  'customers/post',
  async (data: Customer): Promise<any> => {
    const response = await axios.post(`${api}/customers`, data, {
      headers: { authorization: localStorage.getItem('authorization') },
    });

    return response.data;
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/update',
  async (data: any): Promise<any> => {
    const response = await axios.put(`${api}/customers/${data.id}`, data);

    return response.data;
  }
);

export const deleteCustomer = createAsyncThunk(
  'customers/delete',
  async (customerId: string | undefined) => {
    const response = await axios.delete(`${api}/customers/${customerId}`);

    return response.data;
  }
);

const initialState: InitialState = {
  pending: false,
  error: false,
  customer: {},
  customer_list: {
    results: [],
    total_pages: 0,
  },
};

const CustomerSlice = createSlice({
  name: 'customer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCustomers.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getAllCustomers.rejected, (state) => {
      state.error = true;
    });
    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.customer_list.results = action.payload.customers;
      state.customer_list.total_pages = action.payload.total_pages;
    });
    builder.addCase(getOneCustomer.fulfilled, (state, action) => {
      state.customer = action.payload.customer;
    });
  },
});

export default CustomerSlice.reducer;
