import React from 'react';
import type { Customer as ICustomer } from '../../store/types/CustomerTypes';
import Customer from './Customer';

type Props = {
  customers: ICustomer[];
  filters: any;
};

const headerItems = ['Name', 'Contact No.', 'E-mail', 'Address', ''];

const CustomerList: React.FC<Props> = ({ customers, filters }) => {
  return (
    <div className='w-full px-10 py-3 mt-5'>
      <table className='w-full leading-normal shadow-md rounded-lg'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <Customer key={customer.id} customer={customer} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerList;
