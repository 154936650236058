import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import Login from './components/User/Login';
import './App.css';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authorization = localStorage.getItem('authorization');
    !authorization && navigate('/login');
  }, [navigate]);

  return (
    <div className='relative'>
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='/*' element={<Layout />} />
      </Routes>
    </div>
  );
};

export default App;
