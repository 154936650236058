import React, { useState, useEffect } from 'react';
import { FaSave, FaTrash, FaWindowClose } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import {
  deleteUser,
  getAllUsers,
  getOneUser,
  resetDelete,
  updateUser,
} from '../../store/UserSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from "../Layout";
import { FaArrowCircleLeft } from 'react-icons/fa';
import DeleteModal from "./DeleteModal";



const UserDetails = () => {
  const [read_only, setread_only] = useState(true);
  const [delete_modal, setdelete_modal] = useState(false);

  const admin = JSON.parse(localStorage.getItem('user') || '');

  const deleteOneUser = useSelector((state: RootState) => state.user.delete);

  const { id } = useParams();

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const submitUpdates = async () => {

    await dispatch(updateUser(user_details));
    await dispatch(getAllUsers());

    setread_only(true);
  };

  const user = useSelector((state: RootState) => state.user.one_user);

  const [user_details, setuser_details] = useState(user);

  const removeUser = async () => {
    await dispatch(deleteUser(id));
    setdelete_modal(false);
  };

  useEffect(() => {
    dispatch(resetDelete());
    dispatch(getOneUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (deleteOneUser.success) navigate('/users');
    return;
  }, [deleteOneUser, navigate]);

  return (
    <Layout>
      <div className=' flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <div className='flex items-center'>
          <button
            onClick={() => navigate('/users')}
            className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
          >
            <FaArrowCircleLeft />
          </button>
          <h1 className='font-semibold uppercase py-2 '>
            {read_only ? "User's Details" : 'Edit User'}
          </h1>
        </div>


        {admin.admin && (
          <div className='flex items-center justify-between space-x-1'>
            {read_only ? (
              <button
                onClick={() => setread_only(false)}
                // className='text-md text-white bg-blue-600 rounded p-2'
                className='bg-green-600 py-2 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 ml-5'
              >
                Edit User
              </button>
            ) : (
              <div className='flex items-center justify-between space-x-1'>
                {/* Cancel button */}
                <button
                  onClick={() => setread_only(true)}
                  className='bg-gray-600 py-2 px-2 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-gray-700 duration-100 ml-5'
                >
                  <FaWindowClose />
                </button>

                <button
                  onClick={() => submitUpdates()}
                  className='bg-green-600 py-2 px-2 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 ml-5'
                >
                  <FaSave />
                </button>
                <button
                  onClick={() => setdelete_modal(true)}
                  className='bg-red-600 py-2 px-2 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-red-700 duration-100 ml-5'
                >
                  <FaTrash />
                </button>
              </div>
            )}

          </div>
        )}
      </div>
      <div
        className='flex flex-col justify-center items-center w-full'
      >
        <div className='flex flex-col justify-center items-center w-full'>
          <div className='bg-white rounded w-full px-5 lg:px-10'>
            <table className='w-full'>
              <tbody>
                <tr>
                  <td className='px-2 py-2'>
                    <label>First Name :</label>
                  </td>
                  <td className='px-2 py-2'>
                    {read_only ? (
                      <p className='border-b px-3 py-2 rounded'>{user.first_name}</p>
                    ) : (
                      <input
                        className='border border-gray-400 px-3 py-2 rounded'
                        readOnly={read_only}
                        type='text'
                        defaultValue={user_details.first_name}
                        onChange={(e) =>
                          setuser_details({
                            ...user_details,
                            first_name: e.target.value,
                          })
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className='px-2 py-2'>
                    <label>Last Name :</label>
                  </td>
                  <td className='px-2 py-2'>
                    {read_only ? (
                      <p className='border-b px-3 py-2 rounded'>{user.last_name}</p>
                    ) : (
                      <input
                        className='border border-gray-400 px-3 py-2 rounded'
                        readOnly={read_only}
                        type='text'
                        defaultValue={user_details.last_name}
                        onChange={(e) =>
                          setuser_details({
                            ...user_details,
                            last_name: e.target.value,
                          })
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className='px-2 py-2'>
                    <label>E-mail address :</label>
                  </td>
                  <td className='px-2 py-2'>
                    {read_only ? (
                      <p className='border-b px-3 py-2 rounded'>{user.email_address}</p>
                    ) : (
                      <input
                        className='border border-gray-400 px-3 py-2 rounded'
                        readOnly={read_only}
                        type='text'
                        defaultValue={user_details.email_address}
                        onChange={(e) =>
                          setuser_details({
                            ...user_details,
                            email_address: e.target.value,
                          })
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className='px-2 py-2'>
                    <label>Username :</label>
                  </td>
                  <td className='px-2 py-2'>
                    {read_only ? (
                      <p className='border-b px-3 py-2 rounded'>{user.user_name}</p>
                    ) : (
                      <input
                        className='border border-gray-400 px-3 py-2 rounded focus:border-none'
                        readOnly={read_only}
                        type='text'
                        defaultValue={user_details.user_name}
                        onChange={(e) =>
                          setuser_details({
                            ...user_details,
                            user_name: e.target.value,
                          })
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className='px-2 py-2'>
                    <label>User Role :</label>
                  </td>
                  <td className='px-2 py-2'>
                    {read_only ? (
                      <p className='border-b px-3 py-2 rounded font-semibold'>
                        {user.admin ? 'Admin'.toUpperCase() : 'User'.toUpperCase()}
                      </p>
                    ) : (
                      <select
                        disabled={read_only}
                        className='border border-gray-400 px-3 py-2 rounded focus:border-none'
                        onChange={() =>
                          setuser_details({ ...user_details, admin: !user_details.admin })
                        }
                      >
                        <option value={'admin'}>{user.admin ? 'Admin' : 'User'}</option>
                        <option value={'user'}>{user.admin ? 'User' : 'Admin'}</option>
                      </select>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        {delete_modal && (
          <DeleteModal
            setdelete_modal={setdelete_modal}
            removeUser={removeUser}
          />
        )}
        {deleteOneUser.error && (
          <div className='absolute py-2 px-4 text-white font-semibold top-0 left-0 bg-red-600 w-full'>
            {deleteOneUser.message}
          </div>
        )}
      </div>

    </Layout>



  );
};

export default UserDetails;
