import React from 'react';
import Product from './Product';
import { Product as TProduct } from '../../store/types/ProductTypes';

type Props = {
  products: TProduct[];
};

const headerItems = [
  'Title',
  'Type',
  'Added Date',
  'Total Cost',
  'Total Sales',
  'Options',
];

const ProductList: React.FC<Props> = ({ products }) => {
  return (
    <div className='w-full px-10 py-3 mt-5'>
      <table className='w-full leading-normal shadow-md rounded-lg'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductList;
