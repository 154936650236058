import React from 'react';
import { FaTrash } from 'react-icons/fa';

type Props = {
  setdeleteModal: any;
};

const Options: React.FC<Props> = ({ setdeleteModal }) => {
  return (
    <td className='absolute top-6 right-24 bg-white z-10 text-xs'>
      <li className='list-none p-1 border-b border-x'>
        <button
          onClick={() => setdeleteModal(true)}
          className='flex items-center'
        >
          <FaTrash className='mr-1' /> Delete
        </button>
      </li>
    </td>
  );
};

export default Options;
