import React, { useState, useEffect } from 'react';
import type { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers } from '../../store/CustomerSlice';
import Select from 'react-select';
import { postReceipt } from '../../store/ReceiptSlice';
import { Receipt } from '../../store/types/ReceiptTypes';
import { generateUniqueId } from '../../utils/unique-no';
import { FaPlus } from 'react-icons/fa';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleLeft } from 'react-icons/fa';

const filters = {
  search: '',
  page: 0,
  limit: 10,
};

const NewReceipt = () => {
  const receipt_no = generateUniqueId('STR');

  const [receipt, setreceipt] = useState<Receipt>({
    customer_name: '',
    date: '',
    address: [''],
    email: '',
    receipt_no: receipt_no,
    items: [],
    payment_method: '',
    payment_reference: '',
  });

  const navigate = useNavigate();

  const [item, setitem] = useState<any>({ description: '', price: '' });

  const customers = useSelector(
    (state: RootState) => state.customer.customer_list.results
  );

  const dispatch: AppDispatch = useDispatch();

  const customerOptions = customers.map((customer) => ({
    label: customer.first_name + ' ' + customer.last_name,
    value: customer,
  }));

  const paymentMethodOptions = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Cheque', value: 'Cheque' },
    { label: 'Card', value: 'Card' },
  ];

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await dispatch(postReceipt(receipt));

    navigate('/receipts');
  };

  useEffect(() => {
    dispatch(getAllCustomers(filters));
  }, [receipt, dispatch]);

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex  items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <div className='flex items-center'>
          <button
            onClick={() => navigate('/receipts')}
            className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
          >
            <FaArrowCircleLeft />
          </button>
          <h1 className='font-semibold py-2'>NEW RECEIPT</h1>
        </div>
      </div>
      <form
        onSubmit={(e) => handleSubmit(e)}
        className='shadow-lg rounded w-2/3 my-20 p-10 flex flex-col '
      >
        <h2 className='font-semibold capitalize mb-1 text-lg'>
          Create Receipt
        </h2>
        <p className='font-semibold capitalize mb-4 text-sm text-gray-400'>
          Fill the form to create new receipt
        </p>

        <div className='flex justify-evenly gap-5'>
          <div className='w-full'>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Customer:{' '}
              </label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingTop: 4,
                    paddingBottom: 5,
                  }),
                }}
                className='text-sm rounded'
                options={customerOptions}
                onChange={(e) => {
                  setreceipt({
                    ...receipt,
                    customer_name:
                      e?.value.first_name + ' ' + e?.value.last_name || '',
                    address: e?.value.address || [],
                    email: e?.value.email_address || '',
                  });
                }}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Address Line 1:{' '}
              </label>
              <input
                required
                placeholder='Address Line 1'
                className='border py-3 text-sm px-2 rounded'
                type='text'
                readOnly
                defaultValue={receipt.address[0]}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Address Line 2:{' '}
              </label>
              <input
                required
                placeholder='Address Line 2'
                className='border py-3 text-sm px-2 rounded'
                type='text'
                readOnly
                defaultValue={receipt.address[1]}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Address Line 3:{' '}
              </label>
              <input
                required
                placeholder='Address Line 3'
                className='border py-3 text-sm px-2 rounded'
                type='text'
                readOnly
                defaultValue={receipt.address[2]}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                E-mail Adress:{' '}
              </label>
              <input
                required
                placeholder='E-mail'
                className='border py-3 text-sm px-2 rounded'
                type='text'
                readOnly
                defaultValue={receipt.email}
              />
            </div>
          </div>

          {/* Column 2 */}
          <div className='w-full'>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Receipt No:{' '}
              </label>
              <input
                required
                placeholder='Address Line 2'
                className='border py-3 text-sm px-2 rounded'
                type='text'
                readOnly
                defaultValue={receipt_no}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Payment Method:{' '}
              </label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingTop: 4,
                    paddingBottom: 5,
                  }),
                }}
                className='text-sm rounded'
                options={paymentMethodOptions}
                onChange={(e) => {
                  setreceipt({
                    ...receipt,
                    payment_method: e?.value || '',
                  });
                }}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='flex items-center'>Date: </label>
              <input
                required
                onChange={(e) =>
                  setreceipt({ ...receipt, date: e.target.value })
                }
                placeholder='Address Line 1'
                className='border py-3 text-sm px-2 rounded text-gray-500'
                type='date'
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Payment Reference:{' '}
              </label>
              <input
                required
                placeholder='Payment Reference'
                className='border py-3 text-sm px-2 rounded'
                type='text'
                onChange={(e) =>
                  setreceipt({ ...receipt, payment_reference: e.target.value })
                }
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                E-mail Adress:{' '}
              </label>
              <input
                required
                placeholder='E-mail'
                className='border py-3 text-sm px-2 rounded'
                type='text'
                readOnly
                defaultValue={receipt.email}
              />
            </div>
          </div>
        </div>

        <hr className='my-8 border border-gray-300 border-dotted' />
        {/* Items List */}
        <div className='w-full'>
          <h2 className='font-semibold  mb-4'>Receipt Items:</h2>

          <div className='rounded border p-2 w-full my-2'>
            {receipt.items.map((item: any, idx: number) => (
              <div
                key={idx}
                className='flex border-b py-1 justify-between text-sm'
              >
                <p>{item.description}</p>
                <p>Rs. {item.price}</p>
              </div>
            ))}
            <p className='flex justify-between mt-4 text-sm font-semibold'>
              <span>Total:</span>
              Rs. {receipt.items.reduce((a: any, c: any) => a + c.price, 0)}
            </p>
          </div>
        </div>
        <div className='flex items-end mb-5 w-full justify-center'>
          <div className='flex flex-col mx-1 w-full '>
            <label className='my-1 text-sm text-gray-500 font-semibold'>
              Item Description:{' '}
            </label>
            <input
              onChange={(e) =>
                setitem({ ...item, description: e.target.value })
              }
              placeholder='Description'
              className='border py-3 text-sm px-2 rounded'
              type='text'
            />
          </div>
          <div className='flex flex-col mx-1'>
            <label className='my-1 text-sm text-gray-500 font-semibold'>
              Price:{' '}
            </label>
            <input
              onChange={(e) => {
                setitem({ ...item, price: Number(e.target.value) });
              }}
              placeholder='Price'
              className='border py-3 text-sm px-2 rounded'
              type='number'
            />
          </div>
          <button
            type='button'
            onClick={() => {
              receipt.items.length < 5 &&
                setreceipt({ ...receipt, items: [...receipt.items, item] });
            }}
            className='bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100'
          >
            <FaPlus />
          </button>
        </div>

        <button
          disabled={receipt.items.length === 0}
          type='submit'
          className='bg-green-600 py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 w-full mt-auto'
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default NewReceipt;
