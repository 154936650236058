import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import type { InitialState } from "./types/BoqTypes";

const api = process.env.REACT_APP_API;

export const getAllTemplates = createAsyncThunk(
  "boq_templates/get",
  async (): Promise<any> => {
    const response = await axios.get(`${api}/boqs/templates`);

    return response.data.boq_templates;
  }
);

export const getAllBoqs = createAsyncThunk(
  "boqs/get",
  async (filters: any): Promise<any> => {
    const response = await axios.get(
      `${api}/boqs?page=${filters.page}&limit=${filters.limit}}`
    );

    return response.data.boqs;
  }
);

export const putBoq = createAsyncThunk(
  "boqs/put",
  async (data: any): Promise<any> => {
    const response = await axios.put(`${api}/boqs/${data.id}`, data.data, {
      headers: { authorization: localStorage.getItem("authorization") },
    });

    return response.data;
  }
);

export const postBoq = createAsyncThunk(
  "boqs/post",
  async (data: any): Promise<any> => {
    const response = await axios.post(`${api}/boqs`, data, {
      headers: { authorization: localStorage.getItem("authorization") },
    });

    return response.data;
  }
);

export const deleteBoq = createAsyncThunk(
  "boqs/delete",
  async (id: string): Promise<any> => {
    const response = await axios.delete(`${api}/boqs/${id}`);

    return response.data;
  }
);

const initialState: InitialState = {
  pending: false,
  error: false,
  boq_list: {
    results: [],
    total_pages: 0,
  },
  boq_template_list: [],
};

const BoqSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTemplates.fulfilled, (state, action) => {
      state.boq_template_list = action.payload;
    });
    builder.addCase(getAllBoqs.fulfilled, (state, action) => {
      state.boq_list.results = action.payload;
    });
    builder.addCase(putBoq.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(putBoq.fulfilled, (state, action) => {
      state.pending = false;
    });
  },
});

export default BoqSlice.reducer;
