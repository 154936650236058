import React from 'react';
import Boq from './Boq';

type Props = {
  boqs: any[];
};

const headerItems = ['Project ID', 'BOQ Selected', 'BOQ Status', 'Options'];

const BoqList: React.FC<Props> = ({ boqs }) => {
  return (
    <div>
      <div className='w-full px-10 py-3 mt-5'>
        <table className='w-full leading-normal shadow-md rounded-lg'>
          <thead>
            <tr>
              {headerItems.map((item) => (
                <th
                  key={item}
                  className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {boqs.map((boq) => (
              <Boq boq={boq} key={boq.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BoqList;
