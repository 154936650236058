import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard/Dashboard';
import Customers from './components/Customers/Customers';
import NewCustomer from './components/Customers/NewCustomer';
import Projects from './components/Projects/Projects';
import NewProject from './components/Projects/NewProject';
import ProjectDetails from './components/Projects/ProjectDetails';
import ProjectSuccess from './components/Projects/ProjectSuccess';
import Receipts from './components/Receipts/Receipts';
import NewReceipt from './components/Receipts/NewReceipt';
import Tasks from './components/Tasks/Tasks';
import Logs from './components/Logs/Logs';
import { setCurrentUser } from './store/UserSlice';
import { AppDispatch } from './store';
import Users from './components/Users/Users';
import NewUser from './components/Users/NewUser';
import Products from './components/Products/Products';
import Invoices from './components/Invoices/Invoices';
import NewInvoice from './components/Invoices/NewInvoice';
import Boqs from './components/Boqs/Boqs';
import NewBoq from './components/Boqs/NewBoq';
import CustomerDetails from './components/Customers/CustomerDetails';
import UserDetails from './components/Users/UserDetails';

const Layout = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('authorization') || '';

    dispatch(setCurrentUser(token));
  }, [dispatch]);
  return (
    <div className='h-screen overflow-hidden'>
      <Header />
      <div className='flex'>
        <NavBar />
        <Routes>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='customers' element={<Customers />} />
          <Route path='customers/details/:id' element={<CustomerDetails />} />
          <Route path='customers/new-customer' element={<NewCustomer />} />
          <Route path='projects' element={<Projects />} />
          <Route path='projects/new-project' element={<NewProject />} />
          <Route path='projects/details/:id' element={<ProjectDetails />} />
          <Route
            path='projects/details/success/:id'
            element={<ProjectSuccess />}
          />
          <Route path='receipts' element={<Receipts />} />
          <Route path='receipts/new-receipt' element={<NewReceipt />} />
          <Route path='my-tasks' element={<Tasks />} />
          <Route path='activity-logs' element={<Logs />} />
          <Route path='users' element={<Users />} />
          <Route path='users/new-user' element={<NewUser />} />
          <Route path='users/:id' element={<UserDetails />} />
          <Route path='products-and-services' element={<Products />} />
          <Route path='invoices' element={<Invoices />} />
          <Route path='invoices/new-invoice' element={<NewInvoice />} />
          <Route path='boqs' element={<Boqs />} />
          <Route path='boqs/new-boq' element={<NewBoq />} />
          
          {/* Default to dashboard */}
          <Route path='*' element={<Dashboard />} />

        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
