import React from 'react';
import type { Receipt as IReceipt } from '../../store/types/ReceiptTypes';
import Receipt from './Receipt';

type Props = {
  receipts: IReceipt[];
};

const headerItems = [
  'Receipt No.',
  'Date',
  'Customer Name',
  'E-mail',
  'Total Amount',
  'Options',
];

const ReceiptList: React.FC<Props> = ({ receipts }) => {
  return (
    <div className='w-full px-10 py-3 mt-5'>
      <table className='w-full leading-normal shadow-md rounded-lg'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {receipts.map((receipt: IReceipt) => (
            <Receipt key={receipt.id} receipt={receipt} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReceiptList;
