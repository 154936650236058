import React from 'react';
import { Log } from '../../store/types/LogTypes';
import { useNavigate } from "react-router-dom";

type Props = {
  logs: Log[];
};

const headerItems = [
  'Date Completed',
  'Task Completed',
  'Customer',
  'Location',
  'Completed By',
];

const LogsList: React.FC<Props> = ({ logs }) => {
  const navigate = useNavigate();
  const reversedLogs = [...logs].reverse();
  return (
    <div className='w-full px-10 py-3 mt-5'>
      <table className='w-full leading-normal shadow-md rounded-lg'>
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className='px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider'
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reversedLogs.map((log: Log) => {
            const address =
              ((log.project?.site_address[0] ?? '') + ' ' +
                (log.project?.site_address[1] ?? '') + ' ' +
                (log.project?.site_address[2] ?? '')).substring(0, 10);
            return (
              <tr key={log.id} className='border-b text-sm cursor-pointer hover:bg-gray-50'
                onClick={() => {
                  navigate(`/projects/details/${log.project_id}`);
                }}
              >
                < td className='py-4 px-10' > {log.createdAt?.split('T')[0]}</td>
                <td className='py-4 px-10'>{log.status}</td>
                <td className='py-4 px-10'>
                  {log.project?.email_address}
                </td>
                <td className='py-4 px-10'>{address}...</td>
                <td className='py-4 px-10'>{log.user?.user_name}</td>
              </tr>
            );
          })}
        </tbody>
      </table >
    </div >
  );
};

export default LogsList;
