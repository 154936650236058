import React, { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import Select from 'react-select';
import type { AppDispatch, RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { IoMdReturnLeft } from 'react-icons/io';
import { getProposal, statusUpdate } from '../../store/ProjectsSlice';

type Props = {
  project_id: string;
  status: string;
  setupdatemodal: any;
  nextStatus: string;
};

const StatusUpdate: React.FC<Props> = ({
  project_id,
  setupdatemodal,
  status,
  nextStatus,
}) => {
  const [updatedata, setupdatedata] = useState({
    user_id: '',
    status: status,
    notes: '',
  });
  // Added state for loading status
  const [isLoading, setIsLoading] = useState(false);

  const users = useSelector((state: RootState) => state.user.all_users);

  const dispatch: AppDispatch = useDispatch();

  const updateProjectUser = async (e: any) => {
    e.preventDefault();
    setIsLoading(true); // set loading to true when update starts

    const data = {
      id: project_id,
      body: updatedata,
    };

    await dispatch(statusUpdate(data));
    await dispatch(getProposal(project_id));
    setIsLoading(false); // set loading to false when update finishes

    return setupdatemodal(false);
  };

  const userOptions = users.map((user) => ({
    label: user.user_name,
    value: user.id,
  }));

  return (
    <div className='z-20 bg-black/75 absolute w-screen h-full top-0 left-0 flex justify-center items-center'>
      <button
        onClick={() => setupdatemodal(false)}
        className='text-3xl text-white absolute top-10 right-14'
      >
        <CgClose />
      </button>
      <div className='bg-white rounded p-6'>
        <h3 className='font-semibold mb-4'>Task Completion - {status}.</h3>
        <form
          onSubmit={(e) => updateProjectUser(e)}
          className='flex w-max justify-center'
        >
          <input
            className='px-3 py-2 rounded border border-gray-400 w-64 mx-2'
            type='text'
            placeholder='Additional Notes...'
            onChange={(e) =>
              setupdatedata({ ...updatedata, notes: e.target.value })
            }
          />
          <Select
            placeholder='Select User'
            options={userOptions}
            onChange={(e) =>
              setupdatedata({ ...updatedata, user_id: e?.value || '' })
            }
          />
          <button
            type='submit'
            disabled={isLoading} // disable button while loading
            className='rounded border border-green-700 text-green-700 px-3 ml-2 hover:text-white hover:bg-green-600 duration-200 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            <IoMdReturnLeft />
          </button>
        </form>
        <h3 className='font-semibold mt-4'>Next Task - {nextStatus}.</h3>
      </div>
    </div>
  );
};

export default StatusUpdate;
