import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { deleteProduct, getAllProducts } from '../../store/ProductSlice';
import Options from './Options';
import UpdateProduct from './UpdateProduct';

type Props = {
  product: any;
};

type DeleteProps = {
  id: string;
};

const Product: React.FC<Props> = ({ product }) => {
  const filters = {
    search: '',
    page: 0,
    limit: 10,
  };

  const [deleteModal, setdeleteModal] = useState(false);

  const [options, setoptions] = useState(false);
  const [updateModal, setupdateModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const removeProduct = async (id: any) => {
    await dispatch(deleteProduct(id));
    dispatch(getAllProducts(filters));
    setdeleteModal(false);
  };

  const DeleteModal: React.FC<DeleteProps> = ({ id }) => {
    return (
      <div>
        <button
          onClick={() => removeProduct(id)}
          className='rounded mx-1 bg-red-600 border border-red-800 text-white font-semibold text-xs p-1'
        >
          Delete
        </button>
        <button
          onClick={() => setdeleteModal(false)}
          className='rounded mx-1 bg-green-600 border border-green-800 text-white font-semibold text-xs p-1'
        >
          Cancel
        </button>
      </div>
    );
  };

  return (
    <tr className='border-b relative text-sm'>
      <td className='py-4 px-10'>{product.product_name}</td>
      <td className='py-4 px-10'>{product.type}</td>
      <td className='py-4 px-10'>{product.createdAt?.split('T')[0]}</td>
      <td className='py-4 px-10'>{product.total_cost}</td>
      <td className='py-4 px-10'>{product.total_sales}</td>
      <td className='py-4 px-10'>
        {updateModal && (
          <UpdateProduct product={product} setupdateModal={setupdateModal} />
        )}
        {deleteModal ? (
          <DeleteModal id={product.id} />
        ) : (
          <button
            className='rounded-full hover:bg-gray-100 duration-200 p-1'
            onClick={() => setoptions(true)}
            onBlur={() =>
              setTimeout(() => {
                setoptions(false);
              }, 200)
            }
          >
            <BsThreeDotsVertical />
          </button>
        )}
      </td>
      {options && (
        <Options
          setupdateModal={setupdateModal}
          setdeleteModal={setdeleteModal}
        />
      )}
    </tr>
  );
};

export default Product;
