import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { postProject, resetPostStatus } from '../../store/ProjectsSlice';
import { getAllCustomers } from '../../store/CustomerSlice';
import type { RootState, AppDispatch } from '../../store';
import type { Project } from '../../store/types/ProjectTypes';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId } from '../../utils/unique-no';
import { navbarHeight, footerHeight } from '../../utils/constants';
import { FaArrowCircleLeft, FaSpinner } from 'react-icons/fa';


const filters = {
  search: '',
  page: 0,
  limit: 100,
};

const NewProject = () => {
  const [project, setproject] = useState<Project>({
    project_id: generateUniqueId('STPR'),
    customer_id: '',
    date: new Date().toISOString().split('T')[0],
    contact_number: '',
    email_address: '',
    site_address: [],
    gps_coordinates: { lat: 0, lon: 0 },
    avg_monthly_usage: 0,
    avg_monthly_bill: 0,
    phase: 'Single',
    amperage: '30A',
    wifi: false,
    roof_shading: 0,
    payment_option: '',
    electricity_acc_no: '',
    system_method: '',
    utility: 'CEB',
    panel_capacity: 0,
    inverter_capacity: 0,
    roof_type: '',
    roof_access: 'Easy',
    ladder_type: 'Short',
  });

  const postingPending = useSelector(
    (state: RootState) => state.project.post.pending
  );
  const postingError = useSelector(
    (state: RootState) => state.project.post.error
  );

  const [bill_img, setbill_img] = useState<any>('');
  const [id_img, setid_img] = useState<any>('');
  const [entrance_img, setentrance_img] = useState<any>('');
  const [bank_account_img, setbank_account_img] = useState<any>('');
  const [map_img, setmap_img] = useState<any>('');
  const [roof_img, setroof_img] = useState<any>('');

  const fd = new FormData();

  const submitProject = async (e: any) => {
    e.preventDefault();
    Object.entries(project).forEach(([key, value]) => {
      fd.append(key, typeof value === 'string' ? value : JSON.stringify(value));
    });

    fd.append('bill_img', bill_img);
    fd.append('id_img', id_img);
    fd.append('entrance_img', entrance_img);
    fd.append('bank_account_img', bank_account_img);
    fd.append('map_img', map_img);
    fd.append('roof_img', roof_img);

    dispatch(postProject(fd));

    Object.entries(project).forEach(([key]) => {
      fd.delete(key);
    });
    fd.delete('bill_img');
    fd.delete('id_img');
    fd.delete('entrance_img');
    fd.delete('bank_account_img');
  };

  const customers = useSelector(
    (state: RootState) => state.customer.customer_list.results
  );

  const postSuccess = useSelector(
    (state: RootState) => state.project.post.success
  );

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const customerOptions = customers.map((customer) => ({
    label: customer.first_name + ' ' + customer.last_name,
    value: customer,
  }));

  const utilityOptions = [
    { label: 'CEB', value: 'CEB' },
    { label: 'LECO', value: 'LECO' },
  ];

  const roofOptions = [
    { label: 'Tile', value: 'Tile' },
    { label: 'Asbestos', value: 'Asbestos' },
    { label: 'Amano', value: 'Amano' },
    { label: 'Combination', value: 'Combination' },
  ];

  const paymentOptions = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Loan', value: 'Loan' },
    { label: 'Credit Card', value: 'Credit Card' },
  ];

  const systemMethodOptions = [
    { label: 'Metering', value: 'Metering' },
    { label: 'Accounting', value: 'Accounting' },
    { label: 'Plus', value: 'Plus' },
  ];

  useEffect(() => {
    dispatch(getAllCustomers(filters));
    postSuccess && navigate('/projects');
    dispatch(resetPostStatus());
  }, [dispatch, navigate, postSuccess]);

  return (
    <div
      className='w-full flex flex-col items-center overflow-y-scroll'
      style={{ maxHeight: `calc(100vh - ${navbarHeight + footerHeight}px)` }}
    >
      <div className='flex  items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <div className='flex items-center'>
          <button
            onClick={() => navigate('/projects')}
            className='mr-3 rounded-l text-orange-300 hover:text-orange-500 duration-100'
          >
            <FaArrowCircleLeft />
          </button>
          <h1 className='font-semibold py-2'>NEW PROJECT</h1>
        </div>
      </div>

      {postingPending && (
        <div className='z-10 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-center'>
          <div className='bg-white rounded py-16 px-20 '>
            <p className='text-blue-600 font-bold rounded flex flex-col items-center gap-4'>
              {/* Animated loading icon */}
              <FaSpinner className='animate-spin text-4xl' />

              Saving project details to the cloud.
              <br />
              This may take up to a minute.
            </p>
          </div>
        </div>
      )}

      {postingError && (
        <div className='z-10 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-center '>
          <div className='bg-white rounded pt-16 pb-12 px-20 '>
            <p className='text-red-600 font-bold rounded'>
              Unexpected server error.
              <br />
              Please try again later..
            </p>
            <button
              className='mt-4 px-4 py-2 bg-gray-600 text-white rounded'
              onClick={() => {
                dispatch(resetPostStatus());
              }}
            >
              Okay
            </button>
          </div>
        </div>
      )}

      <form
        onSubmit={(e) => submitProject(e)}
        className='w-5/6 border rounded p-10 my-14 shadow-lg flex flex-col relative'
      >

        <h2 className='font-semibold capitalize mb-1 text-lg'>
          Add New Project
        </h2>
        <p className='font-semibold capitalize mb-4 text-sm text-gray-400'>
          Please enter project information
        </p>
        <div className='flex justify-between w-full'>
          <div className='w-1/2 p-2'>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Customer:{' '}
              </label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingTop: 4,
                    paddingBottom: 5,
                  }),
                }}
                className='text-sm rounded'
                placeholder='Select Customer'
                options={customerOptions}
                onChange={(e) => {
                  setproject({
                    ...project,
                    customer_id: e?.value.id,
                    contact_number: e?.value.contact_number || '',
                    email_address: e?.value.email_address || '',
                    site_address: e?.value.address || [],
                  });
                }}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Installation Address Line 1:{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    site_address: [
                      e.target.value,
                      ...project.site_address.slice(1),
                    ],
                  });
                }}
                defaultValue={project.site_address[0]}
                type='text'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Address Line 1'
                required
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Installation Address Line 2:{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    site_address: [
                      ...project.site_address.slice(0, 1),
                      e.target.value,
                      ...project.site_address.slice(2),
                    ],
                  });
                }}
                defaultValue={project.site_address[1]}
                type='text'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Address Line 2'
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Installation Address Line 3:{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    site_address: [
                      ...project.site_address.slice(0, 2),
                      e.target.value,
                    ],
                  });
                }}
                defaultValue={project.site_address[2]}
                type='text'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Address Line 3'
              />
            </div>
            <div className='flex gap-2'>
              <div className='flex flex-col w-1/2'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Latitude:{' '}
                </label>
                <input
                  onChange={(e) => {
                    setproject({
                      ...project,
                      gps_coordinates: {
                        ...project.gps_coordinates,
                        lat: Number(e.target.value),
                      },
                    });
                  }}
                  placeholder='Latitude'
                  type='text'
                  className='border py-3 text-sm px-2 rounded'
                  required
                />
              </div>
              <div className='flex flex-col w-1/2'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Longitude:{' '}
                </label>
                <input
                  onChange={(e) => {
                    setproject({
                      ...project,
                      gps_coordinates: {
                        ...project.gps_coordinates,
                        lon: Number(e.target.value),
                      },
                    });
                  }}
                  placeholder='Longitude'
                  type='text'
                  className='border py-3 text-sm px-2 rounded'
                  required
                />
              </div>
            </div>
            <div className='flex items-center'>
              <div className='flex flex-col my-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Bill Image:{' '}
                </label>
                <input
                  className='w-full py-2.5 file:py-1 file:px-2 file:mr-2 file:border-none file:rounded file:hover:cursor-pointer file:text-gray-800 file:bg-gray-100 file:shadow-md  file:text-sm file:hover:bg-gray-200 flex flex-col file:font-semibold file:duration-200 rounded px-1 text-xs'
                  type='file'
                  onChange={(e) => {
                    const files = e.target.files as FileList;
                    setbill_img(files[0]);
                  }}
                  required
                />
              </div>
              <div className='flex flex-col my-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  ID Image:{' '}
                </label>
                <input
                  className='w-full py-2.5 file:py-1 file:px-2 file:mr-2 file:border-none file:rounded file:hover:cursor-pointer file:text-gray-800 file:bg-gray-100 file:shadow-md  file:text-sm file:hover:bg-gray-200 flex flex-col file:font-semibold file:duration-200 rounded px-1 text-xs'
                  type='file'
                  onChange={(e) => {
                    const files = e.target.files as FileList;
                    setid_img(files[0]);
                  }}
                  required
                />
              </div>
            </div>
            <div className='flex items-center'>
              <div className='flex flex-col mb-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Entrance Image:{' '}
                </label>
                <input
                  className='w-full py-2.5 file:py-1 file:px-2 file:mr-2 file:border-none file:rounded file:hover:cursor-pointer file:text-gray-800 file:bg-gray-100 file:shadow-md  file:text-sm file:hover:bg-gray-200 flex flex-col file:font-semibold file:duration-200 rounded px-1 text-xs'
                  type='file'
                  onChange={(e) => {
                    const files = e.target.files as FileList;
                    setentrance_img(files[0]);
                  }}
                  required
                />
              </div>
              <div className='flex flex-col mb-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Bank Acccount Image:{' '}
                </label>
                <input
                  className='w-full py-2.5 file:py-1 file:px-2 file:mr-2 file:border-none file:rounded file:hover:cursor-pointer file:text-gray-800 file:bg-gray-100 file:shadow-md  file:text-sm file:hover:bg-gray-200 flex flex-col file:font-semibold file:duration-200 rounded px-1 text-xs'
                  type='file'
                  onChange={(e) => {
                    const files = e.target.files as FileList;
                    setbank_account_img(files[0]);
                  }}
                  required
                />
              </div>
            </div>
            <div className='flex items-center'>
              <div className='flex flex-col mb-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Map Image:{' '}
                </label>
                <input
                  className='w-full py-2.5 file:py-1 file:px-2 file:mr-2 file:border-none file:rounded file:hover:cursor-pointer file:text-gray-800 file:bg-gray-100 file:shadow-md  file:text-sm file:hover:bg-gray-200 flex flex-col file:font-semibold file:duration-200 rounded px-1 text-xs'
                  type='file'
                  onChange={(e) => {
                    const files = e.target.files as FileList;
                    setmap_img(files[0]);
                  }}
                  required
                />
              </div>
              <div className='flex flex-col mb-3'>
                <label className='my-1 text-sm text-gray-500 font-semibold'>
                  Roof Image:{' '}
                </label>
                <input
                  className='w-full py-2.5 file:py-1 file:px-2 file:mr-2 file:border-none file:rounded file:hover:cursor-pointer file:text-gray-800 file:bg-gray-100 file:shadow-md  file:text-sm file:hover:bg-gray-200 flex flex-col file:font-semibold file:duration-200 rounded px-1 text-xs'
                  type='file'
                  onChange={(e) => {
                    const files = e.target.files as FileList;
                    setroof_img(files[0]);
                  }}
                  required
                />
              </div>
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Utility:{' '}
              </label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingTop: 4,
                    paddingBottom: 5,
                  }),
                }}
                className='text-sm rounded'
                placeholder='Select Provider'
                options={utilityOptions}
                onChange={(e) => {
                  setproject({ ...project, utility: e?.value || '' });
                }}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Roof Type:{' '}
              </label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingTop: 4,
                    paddingBottom: 5,
                  }),
                }}
                className=' text-sm rounded'
                placeholder='Select Roof Type'
                options={roofOptions}
                onChange={(e) => {
                  setproject({ ...project, roof_type: e?.value || '' });
                }}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Roof Shading (%):{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    roof_shading: Number(e.target.value),
                  });
                }}
                type='number'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Shading (%)'
                required
              />
            </div>
          </div>
          <div className='w-1/2 p-2'>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Payment Options:{' '}
              </label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingTop: 4,
                    paddingBottom: 5,
                  }),
                }}
                className='text-sm rounded'
                placeholder='Select Payment Option'
                options={paymentOptions}
                onChange={(e) => {
                  setproject({ ...project, payment_option: e?.value || '' });
                }}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                System Method:{' '}
              </label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    paddingTop: 4,
                    paddingBottom: 5,
                  }),
                }}
                className='text-sm rounded'
                placeholder='Select System Method'
                options={systemMethodOptions}
                onChange={(e) => {
                  setproject({ ...project, system_method: e?.value || '' });
                }}
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Electricity Acc No:{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    electricity_acc_no: e.target.value,
                  });
                }}
                type='text'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Account Number'
                required
              />
            </div>
            <div className='flex flex-col mb-3'>
              <p className='text-sm text-gray-500 font-semibold'>Amperage: </p>
              <div className='flex items-center'>
                <input
                  onChange={(e) => {
                    setproject({ ...project, amperage: e.target.value });
                  }}
                  checked={project.amperage === '30A'}
                  type='radio'
                  value='30A'
                  className='w-4  cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold mr-2'>
                  30A
                </label>
                <input
                  onChange={(e) => {
                    setproject({ ...project, amperage: e.target.value });
                  }}
                  checked={project.amperage === '60A'}
                  type='radio'
                  value='60A'
                  className='w-4 cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold'>
                  60A
                </label>
              </div>
            </div>
            <div className='flex flex-col mb-3'>
              <p className='text-sm text-gray-500 font-semibold'>
                Phase Allocation:{' '}
              </p>
              <div className='flex items-center'>
                <input
                  onChange={(e) => {
                    setproject({ ...project, phase: e.target.value });
                  }}
                  checked={project.phase === 'Single'}
                  type='radio'
                  value='Single'
                  className='w-4 cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold mr-2'>
                  Single
                </label>
                <input
                  onChange={(e) => {
                    setproject({ ...project, phase: e.target.value });
                  }}
                  checked={project.phase === 'Three'}
                  type='radio'
                  value='Three'
                  className='w-4 cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold'>
                  Three
                </label>
              </div>
            </div>
            <div className='flex flex-col mb-3'>
              <p className='text-sm text-gray-500 font-semibold'>
                WiFi Availability:{' '}
              </p>
              <div className='flex items-center '>
                <input
                  className='w-4  cursor-pointer'
                  onChange={() => {
                    setproject({ ...project, wifi: !project.wifi });
                  }}
                  checked={project.wifi}
                  type='checkbox'
                  value='Single'
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold ml-1'>
                  WiFi
                </label>
              </div>
            </div>
            <div className='flex flex-col mb-3'>
              <p className='text-sm text-gray-500 font-semibold'>
                Roof Access:{' '}
              </p>
              <div className='flex items-center'>
                <input
                  onChange={(e) => {
                    setproject({ ...project, roof_access: e.target.value });
                  }}
                  checked={project.roof_access === 'Easy'}
                  type='radio'
                  value='Easy'
                  className='w-4 cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold mr-2'>
                  Easy
                </label>
                <input
                  onChange={(e) => {
                    setproject({ ...project, roof_access: e.target.value });
                  }}
                  checked={project.roof_access === 'Difficult'}
                  type='radio'
                  value='Difficult'
                  className='w-4 cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold'>
                  Difficult
                </label>
              </div>
            </div>
            <div className='flex flex-col pt-1'>
              <p className='text-sm text-gray-500 font-semibold'>
                Ladder Type:{' '}
              </p>
              <div className='flex items-center'>
                <input
                  onChange={(e) => {
                    setproject({ ...project, ladder_type: e.target.value });
                  }}
                  checked={project.ladder_type === 'Short'}
                  type='radio'
                  value='Short'
                  className='w-4 cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold mr-2'>
                  Short
                </label>
                <input
                  onChange={(e) => {
                    setproject({ ...project, ladder_type: e.target.value });
                  }}
                  checked={project.ladder_type === 'Long'}
                  type='radio'
                  value='Long'
                  className='w-4 cursor-pointer'
                  required
                />
                <label className='p-1 my-1 text-sm text-gray-500 font-semibold'>
                  Long
                </label>
              </div>
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Average Consumption (Units):{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    avg_monthly_usage: Number(e.target.value),
                  });
                }}
                type='number'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Average Monthly Usage (Units)'
                required
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Average Bill (Rs.):{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    avg_monthly_bill: Number(e.target.value),
                  });
                }}
                type='number'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Average Monthly Bill (Rs.)'
                required
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Panel Capacity:{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    panel_capacity: Number(e.target.value),
                  });
                }}
                type='number'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Panel Capacity'
                required
              />
            </div>
            <div className='flex flex-col mb-3'>
              <label className='my-1 text-sm text-gray-500 font-semibold'>
                Inverter Capacity:{' '}
              </label>
              <input
                onChange={(e) => {
                  setproject({
                    ...project,
                    inverter_capacity: Number(e.target.value),
                  });
                }}
                type='number'
                className='border py-3 text-sm px-2 rounded'
                placeholder='Inverter Capacity'
                required
              />
            </div>
          </div>
        </div>
        <button
          type='submit'
          disabled={postingPending}
          className={`${postingPending ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-700'
            } py-3 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl duration-100 w-full mt-auto`}
        >
          SUBMIT
        </button>
      </form>
    </div >
  );
};

export default NewProject;
