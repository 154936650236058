import React from 'react';
import type { Customer as ICustomer } from '../../store/types/CustomerTypes';
import { Link } from 'react-router-dom';

type Props = {
  customer: ICustomer;
};

const Customer: React.FC<Props> = ({ customer }) => {

  const { first_name, last_name, email_address, address, contact_number } =
    customer;

  const addressString: string = address?.join(' ');

  return (
    <tr className='border-b text-sm'>
      <td className='py-4 px-10'>
        {first_name.charAt(0).toUpperCase() +
          first_name.slice(1) +
          ' ' +
          last_name.charAt(0).toUpperCase() +
          last_name.slice(1)}
      </td>
      <td className='py-4 px-10'>{contact_number}</td>
      <td className='py-4 px-10'>{email_address}</td>
      <td className='py-4 px-10'>{addressString?.slice(0, 20)}...</td>
      <td className='px-5 py-5 border-b border-gray-200 bg-white  text-right'>
        <Link
          to={`/customers/details/${customer.id}`}
          type='button'
          className='inline-block text-gray-500 hover:text-gray-700'
        >
          Details
        </Link>
      </td>
    </tr>
  );
};

export default Customer;
