import React, { useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import { getAllUsers } from '../../store/UserSlice';
import UserList from './UserList';
import Layout from "../Layout";

const Users = () => {
  const users = useSelector((state: RootState) => state.user.all_users) || [];

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <Layout>
      <div className=' flex justify-between items-center w-full px-10 py-6 border-b border-gray-400 shadow-sm'>
        <h1 className='font-semibold flex items-center gap-3 uppercase py-2 '>
          <span className={`text-orange-400`}>
            <FaUser />
          </span>
          Users
        </h1>
        <Link
          className='bg-green-600 py-2 px-5 rounded text-white font-bold text-sm shadow-lg hover:shadow-xl hover:bg-green-700 duration-100 ml-5'
          to='/users/new-user'
        >
          New User
        </Link>
      </div>
      <UserList users={users} />
    </Layout>
  );
};

export default Users;
