export const generateUniqueId = (str: string) => {
  const date = new Date();
  const year = String(date.getFullYear()).slice(2);
  const month = date.getMonth() + 11;
  const day = date.getDay() + 11;
  const hours = date.getHours() + 10;
  const minutes = date.getMinutes() + 10;
  const seconds = date.getSeconds() + 10;

  const uniqueId = str + year + month + day + hours + minutes + seconds;

  return uniqueId;
};

export const dateString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDay();

  const dateString = `${day < 10 ? '0' + String(day) : String(day)}-${
    month < 10 ? '0' + String(month) : String(month)
  }-${String(year)}`;

  return dateString;
};
