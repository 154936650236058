import { configureStore } from '@reduxjs/toolkit';
import customerReducer from './CustomerSlice';
import projectReducer from './ProjectsSlice';
import receiptReducer from './ReceiptSlice';
import userReducer from './UserSlice';
import logReducer from './LogSlice';
import productReducer from './ProductSlice';
import invoiceReducer from './InvoiceSlice';
import boqReducer from './BoqSlice';
import dashboardReducer from './DashboardSlice';

const store = configureStore({
  reducer: {
    customer: customerReducer,
    project: projectReducer,
    receipt: receiptReducer,
    user: userReducer,
    log: logReducer,
    product: productReducer,
    invoice: invoiceReducer,
    boq: boqReducer,
    dashboard: dashboardReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
