import React from 'react';
import { Link } from 'react-router-dom';
import { Project } from '../../store/types/ProjectTypes';

type Props = {
  tasks: Project[];
};

const headerItems = [
  'Customer',
  'Active Task',
  'Assigned Date',
  'Customer Contact No.',
  'Location',
  'Details',
];

const TaskList: React.FC<Props> = ({ tasks }) => {
  return (
    <div className="w-full px-10 py-3 mt-5">
      <table className="w-full leading-normal shadow-md rounded-lg">
        <thead>
          <tr>
            {headerItems.map((item) => (
              <th
                key={item}
                className="px-10 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <tr key={task.id} className="border-b text-sm">
              <td className="py-4 px-10">
                {task.customer?.first_name + ' ' + task.customer?.last_name}
              </td>
              <td className="py-4 px-10">{task.status}</td>
              <td className="py-4 px-10">{task.updatedAt?.split('T')[0]}</td>
              <td className="py-4 px-10">{task.contact_number}</td>
              <td className="py-4 px-10">{task.site_address[2]}</td>{' '}
              <td className="py-4 px-10 underline ">
                <Link
                  className="ml-auto mt-5  text-xs font-semibold text-gray-500 hover:text-gray-700 hover:underline ease-in-out"
                  to={`/projects/details/${task.id}`}
                >
                  View
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TaskList;
